<template>
   <div class="produce_swiper_wrapper">
    <div class="swiper" id="proSwiper">
    <div class="swiper-wrapper">
      <div class="swiper-slide" v-for="(item,index) in imgList" :key="index">
           <img :src="item.img" alt="">
		   <div class="title">
              {{item.title}}
		   </div>
		   <div class="message">
              {{item.message}}
		   </div>
	  </div>
    </div>
  </div>
   </div>
</template>

<script>
	import Swiper from 'swiper'
    import 'swiper/swiper-bundle.css'
	export default{
	   components:{
		   Swiper
	   },
	   created(){
		 
	   },
       mounted(){
            this.swiperInitPro();
	   },
	   data(){
		   return{
			   imgList:[
                    {
					    img:require('@/assets/img/e-record.png'),
						title:'电子档案一体化',
						message:'业务档案管理系统、档案实时监控系统、人事档案管理系统、2D可视化库房系统'
					    
				   },
				    {
					   img:require('@/assets/img/qualification.png'),
					   title:'待遇资格大数据认证',
					   message:'依托大数据平台代替传统意义上的待遇资格全员认证服务，做到认证服务零打扰，实现“寓认证于无形，寓认证于服务”的总体目标，让数据多跑路，让群众少跑腿。'
					  
				   },
				   {
					   img:require('@/assets/img/supply_new.png'),
					   title:'供需平台系统',
					   message:'基于人力资源市场数据，再通过精准匹配、AI智能推荐等技术，向用人单位和求职者提供个性化的职位推荐、就业培训、员工调剂等服务，实现多渠道、全流程的新型公共就业。'
				   },
                   {
					   img:require('@/assets/img/goverment-data.png'),
					   title:'数据大屏',
					   message:'实时监测企业数据，洞察业务变化规律及未来趋势，数据驱动服务，辅助企业决策，助力企业增长。'
				   },
			   ],

			   
		   }
	   },
	    computed: {
			
		},
	   methods:{
		   swiperInitPro(){ //组件初始化
	            new Swiper("#proSwiper", {
					// loop:true,
					initialSlide:1,
					autoplay:true,
				});
	      },
	   }
	}
</script>

<style lang="scss" scoped>
.produce_swiper_wrapper{
	overflow:hidden;
	#proSwiper{
		height: 245px;
		margin-bottom: 10px;
	}
    .swiper {
      width: 100%;
      height: 245px;
	  
    }
    

	.swiper-slide{
        width: 60%!important;
        margin: 0 20%;
    } 
    .swiper-slide-prev{
        right:-36%;
    }
    .swiper-slide-next{
        left:-36%;
    }

    .swiper-slide {
      text-align: center;
      font-size: 18px;
      background: #fff;
	  display: flex;
	  flex-direction: column;
	  align-items: center;
	  padding:12px;
	  border-radius: 4px;
	  border: 2px solid #3f74ed;

	  .title{
         font-size: 14px;
		 color:#303133;
		 line-height: 22px;
         font-weight: bold;
         margin-bottom: 5px;
	  }
	  .message{
		  font-size: 12px;
		  color:#606266;
		  line-height: 16px;
	  }
    }
	.swiper-slide-active{
		background: #3F74ED;
		.title{
         font-size: 14px;
		 color:#FFFFFF;
		 line-height: 22px;
	  }
	  .message{
		  font-size: 12px;
		  color:#FFFFFF;
		  line-height: 16px;
	   }
	}

    .swiper-slide img {
      display: block;
      width: 165px;
      height: 96px;
      object-fit: cover;
	  margin-bottom:3px;
    }

}

</style>
