<template>
    <div class="footer_wrapper">
        <menuList/>

        <div class="message_wrapper">
            <div class="message_item" v-for="(item,index) in messageList" :key="index">
                 <img :src="item.img" alt="">
                 {{item.text}}
            </div>
        </div>

        <div class="version">@版权所有：山东汉图软件有限公司</div>
        <div class="version"><a href="https://beian.miit.gov.cn/" target="\_blank">鲁ICP备17015510号</a></div>
    </div>
</template>

<script>
import menuList from '../components/menu.vue'
export default {
    components:{
        menuList
    },
    data() {
        return {
           activeName: '1',
           messageList:[
               {
                   text:'山东省济南市高新区齐鲁软件园A1座203',
                   img:require('@/assets/mobile_img/dayinchuanzhen.png'),
               },
                {
                   text:'+400-881-0721',
                   img:require('@/assets/mobile_img/dianhua.png'),
               },
                {
                   text:'0531-88680721',
                   img:require('@/assets/mobile_img/shouye.png'),
               },
                {
                   text:'hitoo@hitoosoft.com',
                   img:require('@/assets/mobile_img/icon-mail.png'),
               },
           ]
        }
    },
}
</script>

<style lang='scss' scoped>
.footer_wrapper{
    width: 100%;
    background: #252934;
    color:#fff;
    padding-bottom: 8px;
    .message_wrapper{
        
        padding:15px 16px;
        border-bottom: 1px solid  #e7e7e7;
        margin-bottom: 15px;
        
        .message_item{
            display: flex;
            align-items: center;
            font-size: 14px;
            color:#8E959F;
            margin-bottom: 10px;
            &:nth-of-type(2n){
               color:#fff;
               font-weight: bold;
            }
            >img{
                width: 16px;
                height: 16px;
                margin-right: 8px;
            }
        }
    }

    .version{
        font-size: 12px;
        color:#9E9E9E;
        margin-bottom: 8px;
        padding: 0 16px;
        >a{
            color:#9E9E9E;
            text-decoration: underline;
        }
    }
}
</style>