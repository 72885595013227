<template>
   <div class='menu_wrapper'>
       <div class="menu_item" v-for="(item,index) in menuList" :key="index" @click="goMenu(item)">
            {{item.text}}
       </div>
   </div>
</template>

<script>
export default {
   data(){
       return{
           menuList:[
               {
                   text:'首页',
                   src:'/'
               },
                {
                   text:'智能硬件',
                   src: "/hardware",
               },
            //     {
            //        text:'关于我们',
            //        src: "/about",
            //    },
           ]
       }
   },
   methods:{
       goMenu(item){
          this.$router.push(item.src);
          this.$emit('close',item.text);
       }
   }
}
</script>

<style lang='scss' scoped>
.menu_wrapper{
    width: 100%;
    padding: 0 16px;
    .menu_item{
        width: 100%;
        height: 50px;
        line-height: 50px;
        font-size: 14px;
        border-bottom: 1px solid  #e7e7e7;
    }
}
</style>