<template>
  <div class="container-box">
    <footer>
      <div class="top">
        <div class="left">
          <div class="list-box" v-for="(item,index) in itemList" :key="index">
            <div class="item-box" v-for="(item1,index1) in item" :key="index1">
              {{item1}}
            </div>
          </div>
        </div>
        <!-- <div class="right">
        </div> -->
      </div>
      <div class="bottom">
        <div class="bottom_container">
          <span>@版权所有：山东汉图软件有限公司  |  <a href="https://beian.miit.gov.cn/" target="\_blank">鲁ICP备17015510号</a></span>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      itemList: [
        ["首页", "公司简介", "数据中台", "政务产品",],
        // ["产品矩阵", "数据中台", "智能运营", "数据大屏", "电子档案一体化", "待遇资格认证", "供需平台系统"],
        ["智能硬件", "产品优势/特点/规格", "核心功能/软件下载","常见问题" ,],
        // ["服务与支持", "软件下载", "常见问题"],
        // ["关于我们", "公司资质"],
        ["联系我们", "地址：济南高新区齐鲁软件园A1座203", "电话：0531-88680721", "传真：0531-88680721", "邮箱：hitoo@hitoosoft.com"]
      ],
      itemList1: [
        
      ]
    };
  }
};
</script>

<style lang="scss">
footer {
  width: 100%;
  // position: fixed;
  // bottom: 0;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  background: #1e2034;
  .top {
    height: 246px;

    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    .left{
      display: flex;
    }
    // .right{
    //   display: flex;
    //   // width: 174px;
    // }
    .list-box{
      margin-right: 64px;
      line-height: 30px;
      font-size: 14px;
      color: #D0D0D0;
      :first-child{
        margin-bottom: 5px;
        color: #fff;
        font-size: 16px;
      }

    }
    .list-box:last-child{
      margin-left: 220px;
      margin-right: 0;
    }
  }
  .bottom {
    width: 100%;
    height: 32px;
    background: #000;
    color: #9E9E9E;
    font-size: 12px;
    .bottom_container {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      > span {
        white-space: nowrap;
        overflow: hidden;
        a{
          color: #9E9E9E;
          &:hover{
            color: #007FFF;
          }
        }
      }
    }
  }
}
</style>