<template>
  <div id="app">
   
   <!--pc端 布局-->
    <template v-if="!isMobile()">
    <!-- <template> -->
        <Header v-show="$route.path!=='/productLicense'" />
        <router-view/>
        <Footer v-show="$route.path!=='/productLicense'" />
    </template>
    

    <!--移动端 显示页面-->
    <div class="mobile_wrapper" v-else>
        <moblieHeader  v-show="$route.path!=='/productLicense'"/>
        <div class="mobile_center">
            <router-view/>
        </div>
        <mobileFooter  v-show="$route.path!=='/productLicense'"/>
    </div>

  </div>
</template>
<script>
import Header from './views/components/header.vue'
import Footer from './views/components/footer.vue'
import mobileFooter from './mobile-pages/layout/footer.vue'
import moblieHeader from './mobile-pages/layout/Header.vue'
export default {
  components:{
    Header,
    Footer,
    moblieHeader,
    mobileFooter
  },
  created() {
    console.log(this.$route.path)
  },
  methods:{
    isMobile() {
        const flag = navigator.userAgent.match(
          /(phone|pad|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows phone)/i
        )
        return flag
    }
  }
}
</script>
<style lang="scss">
@import 'assets/scss/reset.scss';
* {
  margin: 0;
  padding: 0;
}
.mobile_wrapper{
   width: 100%;
   height: 100%;
   background: #F9FAFE;
   .mobile_center{
     width: 100%;
   }
}
</style>
