<template>
    <div class="product-license">
        <el-card>
            <h1>产品授权</h1>

            <el-form ref="licenseForm" :model="form" label-width="100px" :rules="rules"
                @submit.native.prevent="generateLicense">
                <el-form-item label="产品码" prop="snCode">
                    <el-input v-model="form.snCode" placeholder="请输入产品码" clearable></el-input>
                </el-form-item>

                <el-form-item label="机器码" prop="machineCode" style="display: none;">
                    <el-input v-model="form.machineCode" placeholder="请输入机器码" clearable></el-input>
                </el-form-item>

                <el-form-item>
                    <el-button type="primary" native-type="submit">生成授权码</el-button>
                    <el-button type="success" @click="copyLicense" v-if="licenseGenerated">复制授权码</el-button>
                </el-form-item>
            </el-form>

            <el-divider></el-divider>
            <div class="licenseGenerated" v-if="licenseGenerated">
                <span>授权码：</span>
                <el-input type="textarea" v-model="license" readonly></el-input>
            </div>
        </el-card>
    </div>
</template>

<script>
import { getAuthCode } from '@/api/license.js';
export default {
    name: 'ProductLicense',

    data() {
        return {
            flag: false,
            form: {
                snCode: '',
                machineCode: ''
            },
            license: '',
            licenseGenerated: false,
            rules: {
                snCode: [
                    { required: true, message: '请输入产品码', trigger: 'blur' }
                ],
                machineCode: [
                    { required: true, message: '请输入机器码', trigger: 'blur' }
                ]
            }
        };
    },
    created() {
        this.form.machineCode = this.$route.query.machineCode
    },
    methods: {
        async getAuthCode() {
            const res = await getAuthCode(this.form);
            if (res.code === 200) {
                this.license = res.data.authCode;
                this.licenseGenerated = true;
                this.$message.success('授权码已生成');
            }
        },
        generateLicense() {
            this.$refs.licenseForm.validate(valid => {
                if (valid) {
                    this.getAuthCode();
                } else {
                    return false;
                } 
            });
        },
        copyLicense() {
            const textarea = document.createElement('textarea');
            textarea.value = this.license;
            document.body.appendChild(textarea);
            textarea.select();
            document.execCommand('copy');
            document.body.removeChild(textarea);
            this.$message.success('授权码已复制到剪贴板');
        }
    }
};
</script>
<style>
.el-message__icon,
.el-message__icon .el-message--error {
    font-size: 14px !important;
}
</style>
<style scoped lang="scss">
.product-license {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;

    h1 {
        font-size: 24px;
        margin-bottom: 20px;
    }



    .licenseGenerated {
        span {
            font-size: 18px;
        }
    }
}
</style>