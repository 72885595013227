<template>
  <div class="profile">
    <div class="title">{{title}}</div>
    <!-- <img src="@/assets/qualifications.png"
    alt="">-->
    <div class="core">{{subTitle}}</div>
  </div>
</template>

<script>
export default {
  name: "",
  components: {},

  props: {
    title: {
      type: String,
      default: ""
    },
    subTitle: {
      type: String,
      default: ""
    }
  },

  data() {
    return {};
  },

  computed: {},

  watch: {},

  created() {},

  methods: {}
};
</script>
<style lang="scss" scoped>
.profile {
  position: relative;
  height: 63px;
  text-align: center;
}
.title {
  user-select: none;
  position: absolute;
  top: 5px;
  left: 50%;
  transform: translateX(-50%);
  min-width: 144px;
  height: 50px;
  font-size: 36px;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #3f74ed;
  line-height: 50px;
  &::after {
    content: '';
    display: block;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 72px;
    height: 4px;
    background: #3F74ED;
    border-radius: 2px 2px 2px 2px;
    opacity: 1;
  }
  // -webkit-background-clip: text;
  // -webkit-text-fill-color: transparent;
}
.core {
  user-select: none;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  height: 34px;
  font-size: 24px;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  line-height: 34px;
  background: linear-gradient(
    180deg,
    rgba(102, 102, 102, 0.5) 0%,
    rgba(102, 102, 102, 0) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
</style>
