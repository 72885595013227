<template>
   <div class="about_wrapper">
        <div class="home_img">
            <div class="title">汉图软件</div>
            <span>提供一站式软件应用服务</span>
        </div>

        

        <commonTitle title="公司资质" sub-title="COMPANY QUALIFICATIONS"/>

        <div class="img_wrapper">
            <img :src="DCMMImg" alt="" id="qualification1">
            <img :src="qualification1Img" alt="" id="qualification1">
            <img :src="qualification4Img" alt="" id="qualification4">
            <img :src="qualification5Img" alt="" id="qualification5">
        </div>


   </div>
</template>

<script>
import commonTitle from "./components/commonTitle.vue";
export default {
    components:{
        commonTitle
    },
    data(){
        return{
            aboutBanner:require('@/assets/mobile_img/aboutBanner.png'),

            qualification1Img:require('@/assets/img/qualification1.png'),
            qualification4Img:require('@/assets/img/qualification4.png'),
            qualification5Img:require('@/assets/img/qualification5.png'),
            DCMMImg:require('@/assets/img/DCMM.jpg'),
        }
    }
}
</script>

<style lang='scss' scoped>
.about_wrapper{
   width: 100%;
    .home_img{
        width: 100%;
        height: 160px;
        background: url('../assets/img/about-banner.png') no-repeat;
        background-size: 100% 100%;
        padding:33px 0 0 16px;
        .title{
            font-size: 24px;
            font-weight: bold;
            margin-bottom: 12px;
        }
        >span{
            font-size: 12px;
            color:#606266;
        }
    }
    .detail{
        color:#303133;
        font-size: 12px;
        line-height: 16px;
        text-indent: 2em;
    }
    .honor_wrapper{
        margin-bottom: 15px;
        >img{
            width: 100%;
            height: 178px;
        }
    }
    .img_wrapper{
        display: flex;
        align-items: center;
        margin-bottom: 15px;
        justify-content: center;
        >img{
            margin-right: 12px;
            width: 70px;
            height: 88px;
            &:last-child{
                margin-right: 0px;
            }
        }
        // #qualification1,#qualification5{
        //      width: 57px;
        //      height: 78px;
        // }
        // #qualification2,#qualification4{
        //     width: 60px;
        //     height: 82px;
        // }
        // #qualification3{
        //     width: 65px;
        //     height: 88px;
        // }
    }
}
</style>