<template>
  <div class="service_wrapper">
        <div class="home_img">
            <img :src="serviceBanner" alt="">
            <commonTitle title="软件下载" sub-title="DOWNLOAD"/>
        </div>

        <div class="machine_wrapper">
           <img :src="machineImg" alt="">
        </div>

        <div class='down_type'>
             <div class="type_item">
                  汉图高拍仪<br/>v6驱动下载
                  <img :src="downloadImg" alt="">
             </div>

             <div class="type_item">
                  汉图高拍仪网页<br/>控件安装程序2.2下载
                  <img :src="downloadImg" alt="">
             </div>
        </div>


        <commonTitle title="常见问题" sub-title="PROBLEM"/>
        
         <div class="problem_img">
            <img :src="problemImg" alt="">
        </div>
 
  </div>
</template>

<script>
import commonTitle from "./components/commonTitle.vue";
export default {
    components:{
        commonTitle
    },
    data(){
        return{
            serviceBanner:require('@/assets/mobile_img/serviceBanner.png'),
            machineImg:require('@/assets/mobile_img/machine.png'),
            downloadImg:require('@/assets/mobile_img/download.png'),
            problemImg:require('@/assets/mobile_img/problem.png'),
        }
    }

}
</script>

<style lang='scss' scoped>
.service_wrapper{
    width: 100%;
    .home_img{
        >img{
            width: 100%;
            height: 140px;
        }
    }
    .problem_img{
        >img{
            width: 100%;
            height: 143px;
        }
    }
    .machine_wrapper{
        width: 100%;
        display: flex;
        justify-content: center;
        >img{
            width: 230px;
            height: 214px;
        }
    }
    .down_type{
        display: flex;
        margin-bottom: 15px;
        .type_item{
            flex:1;
            height: 80px;
            border-radius: 8px;
            background: #ffffff;
            box-shadow: 0 4px 10px 0 #e7e7e74d;
            margin-right: 7px;
            padding-top:8px;
            display: flex;
            flex-direction: column;
            align-items: center;
            font-size: 14px;
            font-weight: bold;
            color:#0654C4;
            line-height: 22px;
            text-align: center;
            &:last-child{
                margin-right: 0px;
            }
            >img{
                width: 16px;
                height: 16px;
            }
        }
    }
}
</style>