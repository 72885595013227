<template>
   <div class="swiper" id="mySwiper">
    <div class="swiper-wrapper">
      <div class="swiper-slide" v-for="(item,index) in imgList" :key="index">
           <img :src="item.img" alt="">
		   <div class="title">
              {{item.title}}
		   </div>
		   <div class="message">
              {{item.message}}
		   </div>
	  </div>
    </div>
  </div>
</template>

<script>
	import Swiper from 'swiper'
    import 'swiper/swiper-bundle.css'
	export default{
	   components:{
		   Swiper
	   },
	   created(){
		 
	   },
       mounted(){
            this.swiperInit();
	   },
	   data(){
		   return{
			   imgList:[
				  {
					   img:require('@/assets/mobile_img/data1.png'),
					   title:'数据采集',
					   message:'支持多种数据采集方式，统一采集、存储和加工，制定标准统一的数据规范，对数据资源进行集中管理和共享。'
				   },
				   {
					   img:require('@/assets/mobile_img/data3.png'),
					   title:'数据共享',
					   message:'开放性设计思想，打破信息孤岛，数据共享应用。易于随时随地的接入各类数据源，能够实现即开即用，节省开发资源'
					  
				   },
				    {
					   img:require('@/assets/mobile_img/data4.png'),
					   title:'数据治理',
					   message:'数据治理体系，涵盖元数据管理、数据质量管理、数据安全管理等重要环节，为大数据的深度利用，提供坚实的基础。'
				   },
				   {
					    img:require('@/assets/mobile_img/data2.png'),
						title:'数据服务',
						message:'在数据资源基础上，对业务主题、风险预警、分析预测做特定分析，将数据封装成服务，进而为企业创造价值。'
					    
				   },
			   ],

			   
		   }
	   },
	    computed: {
			
		},
	   methods:{
		   swiperInit(){ //组件初始化
	            new Swiper("#mySwiper", {
					loop:false,
					initialSlide:1,
				});
	      },
	   }
	}
</script>

<style lang="scss" scoped>
.mySwiper{
	height: 245px;
}
    .swiper {
      width: 100%;
      height: 245px;
	  overflow:hidden;
    }
    

	.swiper-slide{
        // width: 70%!important;
        // margin: 0 15%;

		 width: 60%!important;
        margin: 0 20%;
    } 
    .swiper-slide-prev{
        right:-36%;    //-26
    }
    .swiper-slide-next{
        left:-36%;
    }

    .swiper-slide {
      text-align: center;
      font-size: 18px;
      background: #fff;
	  display: flex;
	  flex-direction: column;
	  align-items: center;
	  padding:12px;
	  border-radius: 4px;
	  border: 2px solid #3f74ed;

	  .title{
         font-size: 14px;
		 color:#3F74ED;
		 line-height: 22px;
		  margin-bottom: 5px;
	  }
	  .message{
		  font-size: 12px;
		  color:#606266;
		  line-height: 16px;
	  }
    }
	.swiper-slide-active{
		background: #3F74ED;
		.title{
         font-size: 14px;
		 color:#FFFFFF;
		 line-height: 22px;
	  }
	  .message{
		  font-size: 12px;
		  color:#FFFFFF;
		  line-height: 16px;
	   }
	}

    .swiper-slide img {
      display: block;
      width: 146px;
      height: 120px;
      object-fit: cover;
	  margin-bottom:3px;
    }



</style>
