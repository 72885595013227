<template>
  <div class="pc_completeContainer">
    <div class="banner">
      <div class="banner-text">
        <div class="img">
          <img src="@/assets/img/banner-text.png" alt />
        </div>
        <div class="line"></div>
        <p class="text-fz-26">山东汉图软件有限公司</p>
      </div>
    </div>
    <!-- <div class="company-profile">
      <common-title title="公司简介" sub-title="COMPANYPROFILE"></common-title>
      <div class="typearea">
        <div class="left">
          <img src="@/assets/img/company-profile.png" alt />
        </div>
        <div class="right">
          <p>山东汉图软件有限公司（以下简称汉图软件）成立于2013年，公司位于济南市高新技术开发区齐鲁软件园。汉图软件是专注于行业应用软件产品研发、推广、销售以及运营服务的高新技术企业。定位于系统解方案 的提供者，利用供给侧和需求侧关系互动来建立无线增值的可能性。</p>
          <p>公司拥有专业的技术研发团队，在文档影像化处理、OCR技术、数据挖掘技术、移动互联应用开发等领域拥有自主知识产权的核心技术，已研发了数字化档案管理，移动应用服务，数据集成及数据挖掘服务、基金监管服务等产品。</p>
          <p>主要面向政府以及企业提供系统建设规划咨询、系统搭建、数据资源建设及运营服务。</p>
          <p>公司以“责任”、“品质”、“创新”、“效率”为发展理念，把持续提供高品质的产品服 务视为公司责任和生存根本，将与客户共赢作为公司持续发展的动力。“快乐工作、认真生 活”是企业的文化信条，公司构建员工持续进步的人文环境和工作氛围，让员工在工作实践 中不断提高，伴随公司一起成长。</p>
        </div>
      </div>
    </div> -->

    <div class="swiper-box">
      <common-title title="公司资质" sub-title="QUALIFICATIONS"></common-title>

      <swiper class="swiper" ref="swiper" :options="swiperOption">
        <swiper-slide v-for="(item,index) in imgList" :key="index">
          <!-- <img :src="item" alt /> -->
            <el-image 
              style="width: 100px; height: 100px"
              :src="item" 
              :preview-src-list="imgList">
            </el-image>
        </swiper-slide>
      </swiper>
    </div>


    <div class="honor">
      <!-- <common-title title="公司荣誉" sub-title="COMPANYHONOR"></common-title>
      <div class="honor-content">
        <img src="@/assets/img/componyHonor.png" alt />
      </div> -->
      <!-- <div class="swiper-btn">
          <img
            src="@/assets/honorleft.png"
            alt
            class="left"
            slot="button-prev"
            @click="horonSwiperPrev"
          />
          <img
            src="@/assets/honorright.png"
            alt
            class="right"
            slot="button-Next"
            @click="horonSwiperNext"
          />
        </div>
        <div class="swiper-text">
        </div>
        <div class="swiper">
          <swiper ref="horonSwiper" :options="horonSwiperOptions">
            <swiper-slide>
              <img src="@/assets/componyHonor.png" alt />
            </swiper-slide>
          </swiper>
        </div>
      <div class="swiper-pagination" slot="pagination"></div>-->
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import { horonSwiper, horonSwiperSlide } from "vue-awesome-swiper";
import commonTitle from "./components/commonTitle.vue";
import "swiper/swiper-bundle.css";
export default {
  name: "about",
  title: "Change direction",
  components: {
    Swiper,
    SwiperSlide,
    horonSwiper,
    horonSwiperSlide,
    commonTitle
  },
  data() {
    return {
      url:'',
      imgList:[
          require('../assets/img/DCMM.jpg'),
          require('../assets/img/qualification1.png'),
          require('../assets/img/qualification4.png'),
          require('../assets/img/qualification5.png'),
      ],
      swiperOption: {
        slidesPerView: 5,
        spaceBetween: 22,
        direction: "horizontal",
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        },
        on: {
          resize: () => {
            this.$refs.swiper.$swiper.changeDirection(
              window.innerWidth <= 960 ? "vertical" : "horizontal"
            );
          }
        }
      },
      horonSwiperOptions: {
        pagination: {
          el: ".swiper-pagination",
          type: "fraction"
        },
        on: {
          resize: () => {
            this.horonSwiper.changeDirection(
              window.innerWidth <= 960 ? "vertical" : "horizontal"
            );
          }
        }
        // Some Swiper option/callback...
      },
      elementScrollTop: 0,
      bannerHeight: 0
    };
  },
  computed: {
    swiper() {
      return this.$refs.swiper.$swiper;
    }
  },
  beforeDestroy() {
    // document.removeEventListener("wheel", this.handleScroll);
  },
  mounted() {
    //   this.swiper.slideTo(0, 1000, false);
    //   this.bannerHeight = this.$refs.aboutBanner.offsetHeight;
    //   document.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    // down() {
    //   const element = document.querySelector("html");
    //   element.scroll({
    //     top: this.$refs.aboutBanner.offsetHeight, // 滚动到的位置，以像素为单位
    //     behavior: "smooth" // 使用平滑滚动效果
    //   });
    //   // element.scrollTop =
    // },
    // handleScroll(e) {
    //   const that = this;
    //   // 处理滚动事件的代码
    //   if (that.elementScrollTop === 9999999) return;
    //   if (
    //     document.documentElement.scrollTop > that.elementScrollTop &&
    //     document.documentElement.scrollTop < that.bannerHeight
    //   ) {
    //     e.stopPropagation();
    //     e.preventDefault();
    //     that.elementScrollTop = 9999999;
    //     const element = document.querySelector("html");
    //     element.scroll({
    //       top: that.$refs.aboutBanner.offsetHeight, // 滚动到的位置，以像素为单位
    //       behavior: "smooth" // 使用平滑滚动效果
    //     });
    //     setTimeout(() => {
    //       that.elementScrollTop = document.documentElement.scrollTop;
    //     }, 2000);
    //     return;
    //   }
    //   that.elementScrollTop = document.documentElement.scrollTop;
    // }
  }
};
</script>
<style lang="scss" scoped>
.pc_completeContainer {
  width: 100%;
  height: 100%;
  overflow: auto;
  .banner {
    width: 100%;
    height: 500px;
    background: url("../assets/img/about-banner.png") no-repeat center;
    background-size: 100% 100%;
    display: flex;
    align-items: center;
    .banner-text {
      position: absolute;
      margin-left: 360px;
      .img {
        width: 485px;
        height: 220px;
        margin-bottom: 50px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .line {
        width: 260px;
        height: 2px;
        background: #3f74ed;
        border-radius: 10px 10px 10px 10px;
      }
      .text-fz-26 {
        margin-top: 10px;
        font-size: 26px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #3f74ed;
        line-height: 36px;
      }
    }
  }
  .company-profile {
    font-size: 16px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 22px;
    .typearea {
      width: 1209px;
      margin: 0 auto;
      display: flex;
      display: flex;
      justify-content: space-between;
      .left {
        width: 599px;
        height: 300px;
        img {
          width: 100%;
          height: 100%;
        }
        p {
          text-indent: 16px;
          width: 563px;
          height: 286px;
          font-size: 16px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          line-height: 22px;
          -webkit-text-fill-color: transparent;
        }
      }
      .right {
        width: 645px;
        width: 563px;
        height: 286px;
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        div {
          font-size: 32px;
          font-weight: bold;
          color: #ffffff;
          margin: 49px 0 41px 0;
        }
        p {
          text-indent: 2rem;
        }
      }
    }
  }
  .profile {
    margin: 40px 0 30px 0;
    .core {
      width: 233px;
      height: 34px;
      font-size: 24px;
      font-family: PingFang SC-Semibold, PingFang SC;
      font-weight: 600;
      line-height: 34px;
      background: linear-gradient(
        180deg,
        rgba(102, 102, 102, 0.5) 0%,
        rgba(102, 102, 102, 0) 100%
      );
      // -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  .swiper-box {
    margin: 0 auto;
    // padding-bottom: 50px;
    width: 1263px;
    position: relative;
    .left,
    .right {
      width: 12px;
      height: 36px;
    }
    .left {
      left: -52px;
    }
    .right {
      right: -52px;
    }
    .swiper {
      height: 300px;
      width: 100%;
      
     
      .swiper-slide {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-weight: bold;
        font-size: 16px;
        // background-color: #09f;
        // background-color: $white;
        // img {
        //   // border-radius: 10px;
        //   // border: 2px solid #CFD2DF;
        // }
        // &:nth-child(1),
        // &:nth-child(5) {
        //   img {
        //     width: 194px;
        //     height: 250px;
        //   }
        // }
        // &:nth-child(2),
        // &:nth-child(4) {
        //   img {
        //     width: 201px;
        //     height: 280px;
        //   }
        // }
        // &:nth-child(3) {
        //   img {
        //     width: 223px;
        //     height: 300px;
        //   }
        // }

          img{
            width: 223px;
            height: 300px;
          }
          .el-image{
            width: 100% !important;
            height: 100% !important;
          }
      }
      // img {
      //   width: 100%;
      //   height: 100%;
      // }
    }
  }
  .honor {
    width: 100%;
    // margin-top: 50px;
    padding-bottom: 50px;
    .honor-content {
      width: 852px;
      height: 441px;
      margin: 0 auto;

      // border: 1px solid #333;
      img {
        box-shadow: 0 4px 8px rgba(231, 231, 231, 0.5);
        height: 100%;
        width: 100%;
      }
    }
    // .swiper-btn {
    //   position: absolute;
    //   bottom: 50px;
    //   width: 180px;
    //   display: flex;
    //   justify-content: space-between;
    //   div {
    //     width: 65px;
    //     height: 49px;
    //     background: #b8b0b0;
    //     opacity: 0.5;
    //     border-radius: 5px;
    //   }
    //   .left {
    //     display: flex;
    //     align-items: center;
    //     justify-content: center;
    //     width: 65px;
    //     height: 49px;
    //     img {
    //       width: 100%;
    //       height: 100%;
    //     }
    //   }
    //   .right {
    //     display: flex;
    //     align-items: center;
    //     justify-content: center;
    //     width: 65px;
    //     height: 49px;
    //     img {
    //       width: 100%;
    //       height: 100%;
    //     }
    //   }
    // }
    // .swiper-text {
    //   position: absolute;
    //   bottom: 50px;
    //   left: 270px;
    //   font-weight: 700;
    //   font-size: 18px;
    //   .current {
    //     color: #eaeaea;
    //   }
    //   .sum {
    //     color: #efefef;
    //   }
    // }
    // .swiper {
    //   position: absolute;
    //   top: -70px;
    //   right: 0;
    //   width: 864px;
    //   height: 441px;

    //   // background-color: pink;
    //   .swiper-container {
    //     height: 100%;
    //   }
    //   img {
    //     width: 100%;
    //     height: 100%;
    //     border-radius: 13px;
    //     border: 2px solid #d5d7e3;
    //   }
    // }
  }
}
</style>
