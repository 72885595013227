<template>
  <div>
    <div class="box">
      <img src="@/assets/img/404.png" alt />
      <button>
        <a :href="homeUrl">去往首页</a>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "noFindPage",
  components: {},

  props: {},

  data() {
    return {};
  },

  computed: {
    homeUrl() {
      return process.env.VUE_APP_SRC;
    }
  },

  watch: {},

  created() {},

  methods: {}
};
</script>
<style lang="scss" scoped>
.box {
  width: 1500px;
  margin: 200px auto 0;
  display: flex;
  align-items: center;
  button {
    width: 200px;
    height: 60px;
    font-size: 28px;
    background-color: rgb(85, 170, 254);
    border-radius: 30px;
    a {
      color: #fff;
    }
  }
}
</style>
