<template>
  <div class="completeContainer">
    <div class="banner">
      <div class="banner-text">
        <p class="text-fz-88">汉图软件</p>
        <p class="text-fz-48">行业解决方案提供者</p>
        <!-- <p class="text-fz-22">助力互联网、人社、公安、金融、教育等行业信息化开发</p> -->
      </div>
    </div>
    <!-- <div class="synopsis">
      <common-title title="公司简介" sub-title="COMPANY PROFILE"></common-title>

      <div class="profileContainer">
        <div class="left">
          <img src="@/assets/img/com-pro.png" alt />
        </div>
        <div class="right">
          <div class="top">
            <div class="descr">
              山东汉图软件有限公司成立于2013年6月，坐落于济南市高新区齐鲁软件园，是一家拥有双软认证的高新
              技术产业的企业。
            </div>
            <div>
              <p class="title">主营方向</p>
              <p>
                数字化档案管理和数据分析，面向政府和企业提供应用系统建设、数据资源建设，数据分析咨询及运营服
                务。
              </p>
            </div>
            <div>
              <p class="title">核心技术领域</p>
              <p>文档影像化处理、OCR技术、大数据分析、数据挖掘技术、移动应用研发等技术支持。</p>
            </div>
          </div>
          <div class="bootom">
            <div class="one">
              <img src="@/assets/img/synopsis1.png" alt />
              <div class="title">双软件认证企业</div>
            </div>
            <div class="one">
              <img src="@/assets/img/synopsis2.png" alt />
              <div class="title">10+优秀解决方案奖项</div>
            </div>
            <div class="one">
              <img src="@/assets/img/synopsis3.png" alt />
              <div class="title">40+专利著作者</div>
            </div>
            <div class="one">
              <img src="@/assets/img/synopsis4.png" alt />
              <div class="title">
                <span>50+细分行业定制</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
   
    <div class="company-profile">
      <common-title title="公司简介" sub-title="COMPANY PROFILE"></common-title>
      <div class="typearea">
        <div class="left">
          <img src="@/assets/img/company-profile.png" alt />
        </div>
        <div class="right">
          <p>山东汉图软件有限公司（以下简称汉图软件）成立于2013年，公司位于济南市高新技术开发区齐鲁软件园。汉图软件是专注于行业应用软件产品研发、推广、销售以及运营服务的高新技术企业。定位于系统解决方案的提供者，利用供给侧和需求侧关系互动来建立无限增值的可能性。</p>
          <p>公司拥有稳定规模的技术研发团队，在文档影像化处理、OCR技术、数据挖掘技术、移动互联应用开发等领域拥有自主知识产权的核心技术，主要面向政府以及企业提供系统建设规划咨询、系统搭建、数据资源建设及运营服务。</p>
          <p>公司以“责任”、“品质”、“创新”、“效率”为发展理念，把持续提供高品质的产品服务视为公司责任和生存根本，将与客户共赢作为公司持续发展的动力。“快乐工作、认真生活”是企业的文化信条，公司构建员工持续进步的人文环境和工作氛围，让员工在工作实践中不断提高，伴随公司一起成长。</p>
        </div>
      </div>
    </div>

   <div class="container_body">
      <div class="dataProduct">
        <common-title title="数据中台" sub-title="DATA PRODUCT"></common-title>
        <p>整合、治理和扩展跨领域、跨平台的数据资源，将数据封装成服务，进而为企业创造价值。</p>

    
        <!-- 数据平台 -->
    <div class="dataPlatform">
      <div class="dataPlatform_body">
        <div class="dataPlatform-item">
          <img src="@/assets/img/data-capture1.png" alt />
          <div class="line"></div>
          <div class="title">数据采集</div>
          <div class="content">
            支持多种数据采集方式，统一采集、存储和
            加工，制定标准统一的数据规范，对数据资
            源进行集中管理和共享。
          </div>
        </div>
        <div class="dataPlatform-item">
          <img src="@/assets/img/data-capture2.png" alt />
          <div class="line"></div>
          <div class="title">数据共享</div>
          <div class="content">
            开放性设计思想，打破信息孤岛，数据共享
            应用。易于随时随地的接入各类数据源，能
            够实现即开即用，节省开发资源
          </div>
        </div>

        <div class="dataPlatform-item">
          <img src="@/assets/img/data-capture3.png" alt />
          <div class="line"></div>
          <div class="title">数据治理</div>
          <div class="content">
            数据治理体系，涵盖元数据管理、数据质量
            管理、数据安全管理等重要环节，为大数据
            的深度利用，提供坚实的基础。
          </div>
        </div>
        <div class="dataPlatform-item">
          <img src="@/assets/img/data-capture4.png" alt />
          <div class="line"></div>
          <div class="title">数据服务</div>
          <div class="content">
            在数据资源基础上，对业务主题、风险预警
            、分析预测做特定分析，将数据封装成服务
            ，进而为企业创造价值。
          </div>
        </div>
      </div>
    </div>

      </div>
      <div class="government">
        <common-title title="政务产品" sub-title="GOVERMENT PRODUCT"></common-title>
        <p>为政府的全面信息化建设提供一体化政务解决方案打通多部门协作，提升政府办公效率。</p>
        <div class="gpverment-product-content-wrapper">
          <div class="gpverment-product-content">
            <div class="product">
              <div :class="[activeNum == index? 'item_active':'item']" v-for="(item,index) in produceList" :key="index" @mouseenter="changePage(item,index)">
                <div class="title">{{item.text}}</div>
                <div class="content">
                  <p>
                    {{item.detail}}
                  </p>
                </div>
              </div>
            </div>

            <img :src="activeImg" alt />

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import commonTitle from "./components/commonTitle.vue";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";

import "swiper/swiper-bundle.css";
export default {
  name: "Home",
  components: {
    Swiper,
    SwiperSlide,
    commonTitle
  },
  data() {
    return {
      activeNum:0,
      activeImg:require('@/assets/img/e-record.png'),

      produceList:[
         {
           text:'电子档案一体化',
           detail:'将各行业领先的档案管理经验相互补充，推出的电子档案系 统，满足档案“收集、管理、保存、利用”的主线业务，为日常档案管理工作与业务工作的绩效提升提供数字档案一体化平台支持。',
           img:require('@/assets/img/e-record.png'),
         },
         {
           text:'待遇资格认证',
           detail:'依托大数据平台代替传统意义上的待遇资格全员认证服务，通过对待遇领取人员全方位行为轨迹分析，动态判断社保待遇领取资格，不需要待遇领域人员参与，来实现静默状态下的无形认证。',
           img:require('@/assets/img/qualification.png'),
         },
         {
           text:'供需平台系统',
           detail:'基于人力资源市场数据，再通过精准匹配、AIi智能推荐等技术，向用人单位和求职者提供个性的职位推荐、就业培训、员工调剂等服务，实现多渠道、全流程的新型公共就业。',
           img:require('@/assets/img/supply_new.png'),
         },
         {
           text:'数据大屏',
           detail:'实时监测企业数据，洞察业务变化规律及未来趋势，数据驱动服务，辅助企业决策，助力企业增长。',
           img:require('@/assets/img/goverment-data.png'),
         },
      ],

      // horonSwiperOptions: {
      //   pagination: {
      //     el: ".swiper-pagination",
      //     type: "fraction"
      //     // clickable: true
      //   },
      //   // pagination: {
      //   //   el: '.swiper-pagination',
      //   //   type: 'fraction',
      //   // },
      //   on: {
      //     resize: () => {
      //       this.horonSwiper.changeDirection(
      //         window.innerWidth <= 960 ? "vertical" : "horizontal"
      //       );
      //     }
      //   }
      // }
    };
  },
  computed: {
    horonSwiper() {
      // return this.$refs.horonSwiper.$swiper;
    }
  },
  mounted() {
    // this.horonSwiper.slideTo(0, 1000, false);
  },
  methods: {
    changePage(item,index){
        this.activeNum = index;
        this.activeImg = item.img;
    }
    // horonSwiperNext() {
    //   this.horonSwiper.slideNext();
    // },
    // horonSwiperPrev() {
    //   this.horonSwiper.slidePrev();
    // }
  }
};
</script>

<style lang="scss" scoped>
.completeContainer {
  width: 100%;
  height: 100%;
  background: #f9fafe;
  font-family: PingFang SC-Regular, PingFang SC;
  
    .company-profile {
    font-size: 16px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 22px;
    .typearea {
      width: 1209px;
      margin: 0 auto;
      display: flex;
      display: flex;
      justify-content: space-between;
      .left {
        width: 599px;
        height: 300px;
        img {
          width: 100%;
          height: 100%;
        }
        p {
          text-indent: 16px;
          width: 563px;
          height: 286px;
          font-size: 16px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          line-height: 22px;
          -webkit-text-fill-color: transparent;
        }
      }
      .right {
        width: 645px;
        width: 563px;
        height: 286px;
        font-size: 16px;
        font-weight: 400;
        line-height: 27px;
        div {
          font-size: 32px;
          font-weight: bold;
          color: #ffffff;
          margin: 49px 0 41px 0;
        }
        p {
          text-indent: 2rem;
        
        }
      }
    }
  }
    .profile {
    margin: 40px 0 30px 0;
    .core {
      width: 233px;
      height: 34px;
      font-size: 24px;
      font-family: PingFang SC-Semibold, PingFang SC;
      font-weight: 600;
      line-height: 34px;
      background: linear-gradient(
        180deg,
        rgba(102, 102, 102, 0.5) 0%,
        rgba(102, 102, 102, 0) 100%
      );
      // -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  .banner {
    width: 100%;
    height: 500px;
    background: url("../assets/img/home-banner.png") no-repeat center;
    background-size: 100% 100%;
    display: flex;
    align-items: center;
    .banner-text {
      margin-left: 360px;
      .text-fz-88 {
        font-size: 88px;
        font-weight: 600;
        color: #0654c4;
        line-height: 123px;
      }
      .text-fz-48 {
        font-size: 48px;
        font-weight: 500;
        color: #0654c4;
        line-height: 67px;
      }
      .text-fz-22 {
        margin-top: 10px;
        font-size: 22px;
        font-weight: 400;
        color: #0654c4;
        line-height: 31px;
      }
    }
  }
  .synopsis {
    width: 100%;
    // height: 448px;
    padding: 39px 0 0 0;

    .profileContainer {
      display: flex;
      justify-content: center;
      margin-top: 24px;
      .left {
        width: 527px;
        height: 350px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .right {
        // width: 663px;
        margin-left: 24px;
        font-family: PingFang SC-Regular, PingFang SC;

        .top {
          width: 644px;

          font-size: 14px;
          font-weight: 400;
          color: #666666;
          line-height: 20px;
          .title {
            margin-top: 16px;
            font-size: 16px;
            font-weight: 500;
            color: #333333;
            line-height: 22px;
          }
          .descr {
            font-size: 14px;
            font-weight: 400;
            color: #333333;
            line-height: 20px;
          }
        }
        .bootom {
          margin-top: 15px;
          width: 100%;
          height: 161px;
          font-size: 14px;
          color: #333333;
          display: flex;
          align-items: end;

          .one {
            width: 162px;
            height: 151px;
            background: #ffffff;
            box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.05);
            display: flex;
            flex-direction: column;
            align-items: center;
            transition: all 0.3s;
            img {
              width: 126px;
              height: 126px;
              display: block;
              margin: 0 auto;
            }
            .title {
              font-size: 14px;
              font-weight: 400;
              color: #3f74ed;
              line-height: 20px;
            }
            &:hover {
              height: 161px;
              background-color: #3f74ed;
              .title {
                font-size: 14px;
                font-weight: 400;
                color: #fff;
                line-height: 20px;
              }
            }
          }
        }
      }
    }
  }
   .dataPlatform {
    width: 100%;
    margin-top: 16px;
    .dataPlatform_body {
      display: flex;
      justify-content: center;
      .dataPlatform-item {
        width: 298px;
        // height: 421px;
        padding-bottom: 10px;
        margin-right: 3px;
        background: #ffffff;
        border-radius: 8px 8px 8px 8px;
        img {
          width: 300px;
          height: 251px;
          margin-top: 43px;
        }
        .line {
          width: 100%;
          height: 1px;
          background: #e3e3e3;
        }
        .title {
          padding: 14px 0;
          text-align: center;
          font-size: 16px;
          font-family: PingFang SC-Semibold, PingFang SC;
          font-weight: 600;
          color: #333333;
          line-height: 22px;
        }
        .content {
          padding: 0 16px;
          font-size: 14px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          line-height: 20px;
        }
        &:hover {
          background: linear-gradient(180deg, #3f74ed 0%, #0654c4 100%);
          .title,
          .content {
            color: #fff;
          }
        }
      }
      .dataPlatform-item:last-child {
        margin-right: 0;
      }
    }
  }
  .container_body {
    width: 100%;
    padding-bottom: 50px;
    .dataProduct {
      margin-top: 50px;
      p {
        margin-top: 5px;
        text-align: center;
        font-size: 12px;
        font-weight: 400;
        color: #9e9e9e;
        line-height: 17px;
      }
      .particulars {
        display: flex;
        justify-content: center;
        margin-top: 32px;
        .two {
          width: 384px;
          height: 480px;
          padding: 16px 17px 0 17px;
          margin-right: 24px;
          background: #ffffff;
          box-shadow: 0px 4px 10px 0px rgba(51, 51, 51, 0.05);
          border-radius: 8px 8px 8px 8px;
          transition: all 0.3s;
          >img {
            width: 360px;
            height: 360px;
            display: block;
            margin: 0 auto;
          }
          .title_wrapper{
            display: flex;
            .right{
              flex:1;
            }
          }
          .title {
            font-size: 16px;
            font-weight: 500;
            color: #333333;
            line-height: 22px;
            // display: inline-block;
            position: relative;
            &::before {
              content: "";
              position: absolute;
              bottom: 0;
              left: 0;
              width: 100%;
              height: 50%;
              background: linear-gradient(
                360deg,
                #3f74ed 0%,
                rgba(63, 116, 237, 0) 100%
              );
              border-radius: 2px 2px 2px 2px;
            }
            border-radius: 2px 2px 2px 2px;
          }
          p {
            text-align: left;
            margin-top: 16px;
            font-size: 14px;
            font-weight: 400;
            color: #666666;
            line-height: 22px;
          }
          &:hover {
            margin-top: -10px;
            background-color: #3f74ed;
            div {
              color: #fff;
              &::before {
                background: linear-gradient(
                  360deg,
                  #ffffff 0%,
                  rgba(63, 116, 237, 0) 100%
                );
              }
            }
            p {
              color: #fff;
            }
          }
        }
        .two:last-child {
          margin-right: 0;
        }
      }
    }
    .government {
      width: 100%;
      margin-top: 50px;
      p {
        margin-top: 5px;
        text-align: center;
        font-size: 12px;
        font-weight: 400;
        color: #9e9e9e;
        line-height: 17px;
      }
      .gpverment-product-content-wrapper {
        display: flex;
        justify-content: center;
      }
      .gpverment-product-content {
        // margin: 0 auto;
        display: flex;
        // justify-content: center;
        // padding-left: 350px;
        padding-top: 26px;
        margin-top: 16px;
        // width: 100%;
        height: 500px;
        background: url("../assets/img/goverment-bg.png") no-repeat;
        background-size: 997px;
        .product {
          width: 514px;
        }
        .item,.item_active {
          display: flex;
          align-items: center;
          padding: 12px;
          height: 110px;
          width: 514px;
          // border-radius: 8px 8px 8px 8px;
          border-bottom: 1px solid #d8d8d8;;
          .title {
            min-width: 112px;
            display: flex;
            align-items: center;
            height: 100%;
            font-size: 16px;
            color: rgba(255, 255, 255, 0.7);
            line-height: 22px;
          }
          .content {
            width: 366px;
            margin-left: 12px;
            font-size: 14px;
            font-weight: 400;
            line-height: 22px;
            p {
              color: rgba(255, 255, 255, 0.7);
              text-align: left;
              font-size: 14px;
              font-weight: 400;
              line-height: 20px;
            }
          }
        }
        .item_active{
            background: #ffffff25;
            color: #ffffff;
            .title,
            p {
              color: #fff;
            }
        } 

        .line {
          width: 514px;
          height: 1px;
          background: #d8d8d8;
          opacity: 0.2;
        }
        img {
          margin-left: 24px;
          margin-top: 40px;
          width: 662px;
          height: 390px;
        }
      }
    }
  }
  .clientCase {
    width: 100%;
    // height: 571px;
    .wrapper {
      width: 1200px;
      // height: 100%;
      margin: 0 auto;
      padding-top: 50px;
      .wrapper_bottom {
        width: 856px;
        // height: 377px;
        margin: 0 auto;
        padding-top: 39px;
        .line {
          margin-bottom: 35px;
          display: flex;
          justify-content: space-between;
          font-size: 14px;
          font-weight: bold;
          color: #333333;
        }
        .secondline {
          font-size: 14px;
          font-weight: bold;
          color: #333333;
          display: flex;
          justify-content: space-between;
          margin-bottom: 35px;
          span {
            display: inline-block;
            width: 186px;
            text-align: center;
            // margin-right: 128px;
          }
        }
      }
    }
  }
}
</style>
<style lang="scss">
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
  border: 0;
}
@font-face {
  font-family: "iconfont"; /* Project id 2737880 */
  src: url("//at.alicdn.com/t/font_2737880_xxls6w6pr1.woff2?t=1628667577569")
      format("woff2"),
    url("//at.alicdn.com/t/font_2737880_xxls6w6pr1.woff?t=1628667577569")
      format("woff"),
    url("//at.alicdn.com/t/font_2737880_xxls6w6pr1.ttf?t=1628667577569")
      format("truetype");
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-youshang:before {
  content: "\e600";
}

.swiper-pagination {
  position: absolute;
  top: 345px;
  left: 170px;
  width: 200px;
  font-size: 18px;
  color: #fff;
}
</style>
