import axios from 'axios';
import { Message } from 'element-ui'
// 创建axios实例
const axiosInstance = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL,
    headers: {
        'Content-Type': 'application/json',
        // 'knife4j-gateway-code': 'ht',
    }
});

// 通用请求方法
const request = async (apiConfig, data) => {
    try {
        const response = await axiosInstance.request({
            method: 'POST',
            url: `?method=${apiConfig.method}`,
            data: {
                reqBase: {
                },
                reqCont: {
                    ...data
                }
            }
        });

        if (response.status === 200 && response.data.code === 200) {
            return response.data;
        } else {
            Message.error(response.data.message || '请求失败');
            throw new Error( response.data.message);
        }
    } catch (error) {
        throw new Error( error.message);
    }
};

export default request;
