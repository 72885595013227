var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"completeContainer"},[_c('div',{staticClass:"banner"},[_vm._m(0),_c('ul',_vm._l((_vm.dataList),function(item,index){return _c('li',{key:index,class:_vm.active == index ? 'active' : '',on:{"click":function($event){_vm.active = index}}},[_vm._v(" "+_vm._s(item)+" ")])}),0)]),(_vm.active === 0)?_c('div',{staticClass:"dataPlatform"},[_vm._m(1)]):_vm._e(),(_vm.active === 1)?_c('div',{staticClass:"intelligentOperation"},[_vm._m(2)]):_vm._e(),(_vm.active === 2)?_c('div',{staticClass:"data_screen"},[_vm._m(3)]):_vm._e(),(_vm.active === 3)?_c('div',{staticClass:"e-record"},[_vm._m(4)]):_vm._e(),(_vm.active === 4)?_c('div',{staticClass:"qualification"},[_vm._m(5)]):_vm._e(),(_vm.active === 5)?_c('div',{staticClass:"supply"},[_vm._m(6)]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"banner-text"},[_c('p',{staticClass:"text-fz-88"},[_vm._v("汉图软件")]),_c('p',{staticClass:"text-fz-48"},[_vm._v("提供一站式软件应用服务")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dataPlatform_body"},[_c('div',{staticClass:"dataPlatform-item"},[_c('img',{attrs:{"src":require("@/assets/img/data-capture1.png"),"alt":""}}),_c('div',{staticClass:"line"}),_c('div',{staticClass:"title"},[_vm._v("数据采集")]),_c('div',{staticClass:"content"},[_vm._v(" 支持多种数据采集方式，统一采集、存储和 加工，制定标准统一的数据规范，对数据资 源进行集中管理和共享。 ")])]),_c('div',{staticClass:"dataPlatform-item"},[_c('img',{attrs:{"src":require("@/assets/img/data-capture2.png"),"alt":""}}),_c('div',{staticClass:"line"}),_c('div',{staticClass:"title"},[_vm._v("数据共享")]),_c('div',{staticClass:"content"},[_vm._v(" 开放性设计思想，打破信息孤岛，数据共享 应用。易于随时随地的接入各类数据源，能 够实现即开即用，节省开发资源 ")])]),_c('div',{staticClass:"dataPlatform-item"},[_c('img',{attrs:{"src":require("@/assets/img/data-capture3.png"),"alt":""}}),_c('div',{staticClass:"line"}),_c('div',{staticClass:"title"},[_vm._v("数据治理")]),_c('div',{staticClass:"content"},[_vm._v(" 数据治理体系，涵盖元数据管理、数据质量 管理、数据安全管理等重要环节，为大数据 的深度利用，提供坚实的基础。 ")])]),_c('div',{staticClass:"dataPlatform-item"},[_c('img',{attrs:{"src":require("@/assets/img/data-capture4.png"),"alt":""}}),_c('div',{staticClass:"line"}),_c('div',{staticClass:"title"},[_vm._v("数据服务")]),_c('div',{staticClass:"content"},[_vm._v(" 在数据资源基础上，对业务主题、风险预警 、分析预测做特定分析，将数据封装成服务 ，进而为企业创造价值。 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"intelligentOperation_body"},[_c('img',{attrs:{"src":require("@/assets/img/intelligentOperation.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"data_screen_body"},[_c('div',{staticClass:"left"},[_c('div',{staticClass:"title"},[_c('p',[_vm._v("数据大屏可视化")]),_c('p',[_vm._v("更直观更智能的决策场景体验")])]),_c('div',{staticClass:"content"},[_c('p',[_vm._v("实时监测企业数据，洞察业务变化规律")]),_c('p',[_vm._v("及未来趋势，数据驱动服务，辅助企业")]),_c('p',[_vm._v("决策，助力企业增长。")])])]),_c('img',{attrs:{"src":require("@/assets/img/data_screen.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"e-record_body"},[_c('div',{staticClass:"left"},[_c('div',{staticClass:"title"},[_c('p',[_vm._v("统一的综合管理平台")]),_c('p',[_vm._v("亦支持模块化部署")])]),_c('div',{staticClass:"content"},[_c('p',[_vm._v("业务档案管理系统")]),_c('p',[_vm._v("人事档案管理系统")]),_c('p',[_vm._v("2D可视化库房系统")]),_c('p',[_vm._v("档案实时监控系统")])])]),_c('img',{attrs:{"src":require("@/assets/img/e-record.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"qualification_body"},[_c('div',{staticClass:"left"},[_c('div',{staticClass:"title"},[_c('p',[_vm._v("为群众 - -")]),_c('p',[_vm._v("解难点·疏堵点·去痛点")])]),_c('div',{staticClass:"content"},[_c('p',[_vm._v("依托大数据平台代替传统意义上的待遇")]),_c('p',[_vm._v("资格全员认证服务，做到认证服务零打")]),_c('p',[_vm._v("扰，实现“寓认证于无形，寓认证于服")]),_c('p',[_vm._v("务”的总体目标，让数据多跑路，让群")]),_c('p',[_vm._v("众少跑腿。")])])]),_c('img',{attrs:{"src":require("@/assets/img/qualification.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"supply_body"},[_c('div',{staticClass:"left"},[_c('div',{staticClass:"title"},[_c('p',[_vm._v("Web端·移动端·小程序")])]),_c('div',{staticClass:"content"},[_c('p',[_vm._v("基于人力资源市场数据，再通过精准匹")]),_c('p',[_vm._v("配、AI智能推荐等技术，向用人单位和")]),_c('p',[_vm._v("求职者提供个性化的职位推荐、就业培")]),_c('p',[_vm._v("训、员工调剂等服务，实现多渠道、全")]),_c('p',[_vm._v("流程的新型公共就业。")])])]),_c('img',{attrs:{"src":require("@/assets/img/supply.png"),"alt":""}})])
}]

export { render, staticRenderFns }