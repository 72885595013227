<template>
  <div class="completeContainer">
    <div class="banner">
      <div class="banner-text">
        <p class="text-fz-88">汉图软件</p>
        <p class="text-fz-48">提供一站式软件应用服务</p>
        <!-- <p class="text-fz-22">高新技术企业、10+优秀解决方案奖项、40+专利著作权、50+细分行业定制</p> -->
      </div>
    </div>

    <div class="download-box">
      <common-title title="软件下载" sub-title="DOWNLOAD"></common-title>

      <div class="download_bottom">
        <div class="left">
          <img src="@/assets/img/download-banner.png" alt />
        </div>
        <div class="right">
          <div class="download v6" @click="downLoad1">
            <span>汉图高拍仪V6.0.5驱动下载</span>
            <img src="@/assets/img/download.png" alt />
          </div>
          <div class="download v2" @click="downLoad2">
            <span>汉图高拍仪v网页控件安装程序2.2下载</span>
            <img src="@/assets/img/download.png" alt />
          </div>
        </div>
      </div>
    </div>
    <div class="question">
      <common-title title="常见问题" sub-title="PROBLEM"></common-title>
      <div class="pro-img">
        <img src="@/assets/img/problem.png" alt />
      </div>
    </div>
  </div>
</template>

<script>
import commonTitle from "./components/commonTitle.vue";

export default {
  name: "service",
  components: {
    commonTitle
  },
  data() {
    return {};
  },
  methods: {
    downLoad1() {
      window.open(
        'https://pan.baidu.com/s/1ojeyzaP2P0ha00CFYUQMJg?pwd=4mtm'
      );
    },
    downLoad2() {
      window.open(
        'https://pan.baidu.com/s/1SoR3lFiQvWR6LCNubC918w?pwd=a27c'
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.completeContainer {
  width: 100%;
  height: 100%;
  background: #f9fafe;
  .banner {
    width: 100%;
    height: 500px;
    background: url("../assets/img/service-banner.png") no-repeat center;
    background-size: 100% 100%;
    display: flex;
    align-items: center;
    .banner-text {
      margin-left: 360px;
      .text-fz-88 {
        font-size: 88px;
        font-family: PingFang SC-Semibold, PingFang SC;
        font-weight: 600;
        color: #0654c4;
        line-height: 123px;
      }
      .text-fz-48 {
        font-size: 48px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #0654c4;
        line-height: 67px;
      }
      .text-fz-22 {
        margin-top: 10px;
        font-size: 22px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #0654c4;
        line-height: 31px;
      }
    }
  }
  .download-box {
    padding-top: 60px;
    margin-bottom: 50px;
    .download_bottom {
      display: flex;
      justify-content: center;
      margin-top: 20px;
      .left {
        width: 779px;
        height: 516px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .right {
        width: 376px;
        .download {
          height: 116px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          background: #ffffff;
          box-shadow: 0px 4px 10px 0px rgba(231, 231, 231, 0.3);
          border-radius: 8px 8px 8px 8px;
          font-size: 18px;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #0654c4;
          line-height: 25px;
          cursor: pointer;
          img {
            width: 24px;
            height: 24px;
            margin: 10px;
          }
        }
        .v6 {
          margin-top: 65px;
        }
        .v2 {
          margin-top: 155px;
        }
      }
    }
    .dot {
      width: 53px;
      margin: 64px auto 0;
      img {
        width: 53px;
        height: 13px;
      }
    }
  }

  .question {
    width: 894px;
    margin: 0 auto;
    padding-bottom: 50px;
    .pro-img {
      height: 266px;
      margin-top: 20px;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
