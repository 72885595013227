<template>
  <div class="completeContainer">
    <div class="banner">
      <div class="banner-text">
        <p class="text-fz-88">汉图软件</p>
        <p class="text-fz-48">提供一站式软件应用服务</p>
        <!-- <p class="text-fz-22">高新技术企业、10+优秀解决方案奖项、40+专利著作权、50+细分行业定制</p> -->
      </div>
      <ul>
        <!-- <li v-for="(item,index) in dataList" :key="index" @click="scroll(index)">
          {{item}}
          <div class="triangle-up" v-if="active==index"></div>
        </li>-->
        <li
          v-for="(item,index) in dataList"
          :key="index"
          :class="active == index ? 'active' : ''"
          @click="active = index "
        >
          {{item}}
          <!-- <div class="triangle-up" v-if="active==index"></div> -->
        </li>
      </ul>
    </div>
    <!-- 数据平台 -->
    <div class="dataPlatform" v-if="active === 0">
      <div class="dataPlatform_body">
        <div class="dataPlatform-item">
          <img src="@/assets/img/data-capture1.png" alt />
          <div class="line"></div>
          <div class="title">数据采集</div>
          <div class="content">
            支持多种数据采集方式，统一采集、存储和
            加工，制定标准统一的数据规范，对数据资
            源进行集中管理和共享。
          </div>
        </div>
        <div class="dataPlatform-item">
          <img src="@/assets/img/data-capture2.png" alt />
          <div class="line"></div>
          <div class="title">数据共享</div>
          <div class="content">
            开放性设计思想，打破信息孤岛，数据共享
            应用。易于随时随地的接入各类数据源，能
            够实现即开即用，节省开发资源
          </div>
        </div>

        <div class="dataPlatform-item">
          <img src="@/assets/img/data-capture3.png" alt />
          <div class="line"></div>
          <div class="title">数据治理</div>
          <div class="content">
            数据治理体系，涵盖元数据管理、数据质量
            管理、数据安全管理等重要环节，为大数据
            的深度利用，提供坚实的基础。
          </div>
        </div>
        <div class="dataPlatform-item">
          <img src="@/assets/img/data-capture4.png" alt />
          <div class="line"></div>
          <div class="title">数据服务</div>
          <div class="content">
            在数据资源基础上，对业务主题、风险预警
            、分析预测做特定分析，将数据封装成服务
            ，进而为企业创造价值。
          </div>
        </div>
      </div>
    </div>
    <!-- 智能运营 -->
    <div class="intelligentOperation" v-if="active === 1">
      <div class="intelligentOperation_body">
        <img src="@/assets/img/intelligentOperation.png" alt />
      </div>
    </div>
    <!-- 数据大屏 -->
    <div class="data_screen" v-if="active === 2">
      <div class="data_screen_body">
        <div class="left">
          <div class="title">
            <p>数据大屏可视化</p>
            <p>更直观更智能的决策场景体验</p>
          </div>
          <div class="content">
            <p>实时监测企业数据，洞察业务变化规律</p>
            <p>及未来趋势，数据驱动服务，辅助企业</p>
            <p>决策，助力企业增长。</p>
          </div>
        </div>
        <img src="@/assets/img/data_screen.png" alt />
      </div>
    </div>
    <!-- 电子档案 -->
    <div class="e-record" v-if="active === 3">
      <div class="e-record_body">
        <div class="left">
          <div class="title">
            <p>统一的综合管理平台</p>
            <p>亦支持模块化部署</p>
          </div>
          <div class="content">
            <p>业务档案管理系统</p>
            <p>人事档案管理系统</p>
            <p>2D可视化库房系统</p>
            <p>档案实时监控系统</p>
          </div>
        </div>
        <img src="@/assets/img/e-record.png" alt />
      </div>
    </div>
    <!-- 大数据 -->
    <div class="qualification" v-if="active === 4">
      <div class="qualification_body">
        <div class="left">
          <div class="title">
            <p>为群众 - -</p>
            <p>解难点·疏堵点·去痛点</p>
          </div>
          <div class="content">
            <p>依托大数据平台代替传统意义上的待遇</p>
            <p>资格全员认证服务，做到认证服务零打</p>
            <p>扰，实现“寓认证于无形，寓认证于服</p>
            <p>务”的总体目标，让数据多跑路，让群</p>
            <p>众少跑腿。</p>
          </div>
        </div>
        <img src="@/assets/img/qualification.png" alt />
      </div>
    </div>
    <!-- 供需平台 -->
    <div class="supply" v-if="active === 5">
      <div class="supply_body">
        <div class="left">
          <div class="title">
            <p>Web端·移动端·小程序</p>
          </div>
          <div class="content">
            <p>基于人力资源市场数据，再通过精准匹</p>
            <p>配、AI智能推荐等技术，向用人单位和</p>
            <p>求职者提供个性化的职位推荐、就业培</p>
            <p>训、员工调剂等服务，实现多渠道、全</p>
            <p>流程的新型公共就业。</p>
          </div>
        </div>
        <img src="@/assets/img/supply.png" alt />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "product",
  components: {},

  props: {},

  data() {
    return {
      dataList: [
        "数据中台",
        "智能运营",
        "数据大屏",
        "电子档案一体化",
        "待遇资格认证",
        "就业服务平台"
      ], //, '资料交换平台'
      active: 0
    };
  },
  methods: {
    // scroll(i) {
    //   const dataPlatform = document.querySelector(".dataPlatform");
    //   const intelligentOperation = document.querySelector(
    //     ".intelligentOperation"
    //   );
    //   const dataScreen = document.querySelector(".data_screen");
    //   const eRecord = document.querySelector(".e-record");
    //   const bigData = document.querySelector(".bigData");
    //   const supply = document.querySelector(".supply");
    //   const dataInterchange = document.querySelector(".data_interchange");
    //   switch (i) {
    //     case 0:
    //       // document.documentElement.scrollTop = dataPlatform.offsetTop
    //       // document.body.scrollTop = dataPlatform.offsetTop
    //       dataPlatform.scrollIntoView({ behavior: "smooth" });
    //       this.active = 0;
    //       break;
    //     case 1:
    //       //   document.documentElement.scrollTop = intelligentOperation.offsetTop
    //       //   document.body.scrollTop = intelligentOperation.offsetTop
    //       intelligentOperation.scrollIntoView({ behavior: "smooth" });
    //       this.active = 1;
    //       break;
    //     case 2:
    //       dataScreen.scrollIntoView({ behavior: "smooth" });
    //       this.active = 2;
    //       break;
    //     case 3:
    //       eRecord.scrollIntoView({ behavior: "smooth" });
    //       this.active = 3;
    //       break;
    //     case 4:
    //       bigData.scrollIntoView({ behavior: "smooth" });
    //       this.active = 4;
    //       break;
    //     case 5:
    //       supply.scrollIntoView({ behavior: "smooth" });
    //       this.active = 5;
    //       break;
    //     case 6:
    //       dataInterchange.scrollIntoView({ behavior: "smooth" });
    //       this.active = 6;
    //       break;
    //   }
    // }
  }
};
</script>
<style lang="scss" scoped>
.completeContainer {
  width: 100%;
  height: 100%;
  background: #f9fafe;
  padding-bottom: 50px;

  .banner {
    position: relative;
    margin-bottom: 90px;
    width: 100%;
    height: 500px;
    background: url("../assets/img/product-banner.png") no-repeat center;
    background-size: 100% 100%;
    display: flex;
    align-items: center;
    .banner-text {
      margin-left: 360px;
      .text-fz-88 {
        font-size: 88px;
        font-family: PingFang SC-Semibold, PingFang SC;
        font-weight: 600;
        color: #0654c4;
        line-height: 123px;
      }
      .text-fz-48 {
        font-size: 48px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #0654c4;
        line-height: 67px;
      }
      .text-fz-22 {
        margin-top: 10px;
        font-size: 22px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #0654c4;
        line-height: 31px;
      }
    }
    ul {
      position: absolute;
      left: 50%;
      bottom: -40px;
      transform: translateX(-50%);
      // width: 1200px;
      display: flex;
      justify-content: center;
      font-size: 14px;
      font-weight: 400;
      color: #ffffff;
      li {
        cursor: pointer;
        margin-right: 12px;
        list-style: none;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 190px;
        height: 80px;
        background-color: #fff;
        font-size: 16px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        box-shadow: 0px 4px 10px 0px rgba(153, 153, 153, 0.3);
        border-radius: 8px 8px 8px 8px;
        &.active {
          background: #3f74ed;
          color: #fff;
        }
        .triangle-up {
          position: absolute;
          bottom: -15px;
          width: 0;
          height: 0;
          border-left: 12px solid transparent;
          border-right: 12px solid transparent;
          border-bottom: 12px solid #fff;
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
  .dataPlatform {
    width: 100%;
    .dataPlatform_body {
      display: flex;
      justify-content: center;
      .dataPlatform-item {
        width: 298px;
        // height: 421px;
        padding-bottom: 10px;
        margin-right: 3px;
        background: #ffffff;
        border-radius: 8px 8px 8px 8px;
        img {
          width: 300px;
          height: 251px;
          margin-top: 43px;
        }
        .line {
          width: 100%;
          height: 1px;
          background: #e3e3e3;
        }
        .title {
          padding: 14px 0;
          text-align: center;
          font-size: 16px;
          font-family: PingFang SC-Semibold, PingFang SC;
          font-weight: 600;
          color: #333333;
          line-height: 22px;
        }
        .content {
          padding: 0 16px;
          font-size: 14px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          line-height: 20px;
        }
        &:hover {
          background: linear-gradient(180deg, #3f74ed 0%, #0654c4 100%);
          .title,
          .content {
            color: #fff;
          }
        }
      }
      .dataPlatform-item:last-child {
        margin-right: 0;
      }
    }
  }
  .intelligentOperation {
    display: flex;
    justify-content: center;
    width: 100%;
    .intelligentOperation_body {
      img {
        width: 1095px;
        height: 462px;
      }
    }
  }
  .data_screen,
  .e-record,
  .qualification,
  .supply {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-left: 114px;
    .data_screen_body,
    .e-record_body,
    .qualification_body,
    .supply_body {
      display: flex;
      .left {
        height: 380px;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      .title {
        p {
          color: #3f74ed;
          font-size: 18px;
          font-family: PingFang SC-Semibold, PingFang SC;
          font-weight: 600;
          color: #3f74ed;
          line-height: 25px;
        }
      }
      .content {
        margin-top: 24px;
        p {
          font-size: 14px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
        }
      }
      img {
        margin-left: 118px;
        width: 730px;
        height: 430px;
      }
    }
  }
  .e-record {
    width: 100%;
    padding-left: 190px;

    // .e-record_body {
    // }
  }
  .qualification {
    width: 100%;
    padding-left: 112px;
    // .qualification_body {
    // }
  }
  .supply {
    width: 100%;
    padding: 0;
    .supply_body {
      img {
        height: 470px;
      }
    }
  }
  .data_interchange {
    width: 100%;
    // .data_interchange_body {
    // }
  }
}
</style>
