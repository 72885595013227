<template>
   <div class="hardware_wrapper">
        <div class="home_img">
            <div class="title">汉图智能硬件</div>
            <span>汉图高拍仪HT-G1000A3</span>
        </div>


        <commonTitle title="产品优势" sub-title="PRODUCT ADVANTAGES"/>
        

        <div class="home_center">
        <div class="product_img">
            <img :src="productImg" alt="">
        </div>


         <commonTitle title="产品特点" sub-title="CHARACTERISTIC"/>

         <div class="characteristic_wrapper">
            <div class="item" v-for="(item,index) in isUnfoldAll?characteristicList:characteristicListAll" :key="index">
                <div class="item_title">
                    <div class="label"></div>
                    {{item.title}}
                </div>
                <div class="text">
                    {{item.sub}}
                </div>
            </div>

            <div class="unfold" @click="unfoldFunc">
                {{isUnfoldAll?'展开全部':'收起'}}
            </div>
         </div>


         <commonTitle title="产品规格" sub-title="SPECIFICATIONS"/>

         <div class="specifications_wrapper">
             <img :src="highImg" alt="">
         </div>



         <div class="detail_img">
             <!-- <img :src="detailImg" alt=""> -->
             <div class="detail_item" v-for="(item,index) in isShowDetail?detailList:detailListAll" :key="index">
                <span>{{item.text}}</span>
                {{item.subText}}
             </div>
             <div class="unfold" @click="isShowFunc">
                {{isShowDetail?'展开全部':'收起'}}
             </div>
         </div>


         <!-- <commonTitle title="八大软件核心功能" sub-title="CORE FUNCTIONS"/> -->


        <!--轮播图-->
         <!-- <div class="carousel_wrapper">
              <img :src="item.img" alt="" v-for="(item,index) in coreList" :key="index">
         </div> -->

        

        <commonTitle title="软件下载" sub-title="DOWNLOAD"/>

        <div class="machine_wrapper">
           <img :src="machineImg" alt="">
        </div>

        <div class='down_type'>
             <div class="type_item">
                  汉图高拍仪<br/>v6驱动下载
                  <img :src="downloadImg" alt="">
             </div>

             <div class="type_item">
                  汉图高拍仪网页<br/>控件安装程序2.2下载
                  <img :src="downloadImg" alt="">
             </div>
        </div>


        <commonTitle title="常见问题" sub-title="PROBLEM"/>
        
         <div class="problem_img">
            <img :src="problemImg" alt="">
        </div>


        </div>
        
   </div>
</template>

<script>
import commonTitle from "./components/commonTitle.vue";
export default {
    components:{
        commonTitle
    },
    data(){
        return{
            hardwareBanner:require('@/assets/mobile_img/hardwareBanner.png'),
            productImg:require('@/assets/mobile_img/advantage.png'),
            highImg:require('@/assets/img/high.png'),
            detailImg:require('@/assets/img/high-content.png'),
            isUnfoldAll:true,
             machineImg:require('@/assets/mobile_img/machine.png'),
            downloadImg:require('@/assets/mobile_img/download.png'),
            problemImg:require('@/assets/mobile_img/problem.png'),
            characteristicListAll:[
                {
                    title:'双摄像头同步拍摄，最大支持A3幅面',
                    sub:'主摄像头1000万高清像素，分辦率可调；副摄像头200万像素,采用广角镜头,可水平270°和上下45°旋转，灵活调整拍摄角度，进行无死角拍摄；支特最大幅面A3，可拍A4、A5、A6A7、证件类'
                },
                {
                    title:'智能触控LED补光灯，轻触并可自如开关LED灯',
                    sub:'配备高亮度LED补光灯，采用智能触控开关控制，只需轻触即可自由开关补光灯，在光线较暗时开启补光灯亦能保障正常的拍摄工作'
                },
                {
                    title:'机身文稿合一体，拍摄定位更精准',
                    sub:'配置高精度流线型硬质文稿合,最大化的缩小了产品面积,所占的合面空间更小,主体支架可微调位 置以实现文稿的精准定位,方便快速放置文稿并保证视频和图像的齐全。能充分保证在拍摄A6、B6、A5、A4、A3尺寸的身份证、单据、开户资料等时的良好曝光聚焦及图像质量'
                },
                {
                    title:'支持YUV、MJPG格式输出',
                    sub:'创新摄像头输出设计，支持YUV、MJPG格式输出，拍摄出来的图片更小，确又不会'
                },
                {
                    title:'提供强大的后期开发和系统集成技术支持',
                    sub:'可提供各种开发环境下的SDK包，包括各种图像获取、参数设置及处理算法（如框选、裁边、纠偏等），供产品无缝集成，还可单独组建基于网络架构的银行影响管理系统'
                },
                {
                    title:'OCR文字识别',
                    sub:'汉图高拍仪搭载了OCR文件识别技术，可自动分析版面，能识别多种文宇、表格、条码、C†+和Java形式语言及特殊符号等，识别率高达98%，支持多语言、符号 和数字的编排识别，识别结果保持与原图像相同的排版，并可一键导出为word或excel 文件'
                },
            ],
            characteristicList:[
                {
                    title:'双摄像头同步拍摄，最大支持A3幅面',
                    sub:'主摄像头1000万高清像素，分辦率可调；副摄像头200万像素,采用广角镜头,可水平270°和上下45°旋转，灵活调整拍摄角度，进行无死角拍摄；支特最大幅面A3，可拍A4、A5、A6A7、证件类'
                },
                {
                    title:'智能触控LED补光灯，轻触并可自如开关LED灯',
                    sub:'配备高亮度LED补光灯，采用智能触控开关控制，只需轻触即可自由开关补光灯，在光线较暗时开启补光灯亦能保障正常的拍摄工作'
                },
                {
                    title:'机身文稿合一体，拍摄定位更精准',
                    sub:'配置高精度流线型硬质文稿合,最大化的缩小了产品面积,所占的合面空间更小,主体支架可微调位 置以实现文稿的精准定位,方便快速放置文稿并保证视频和图像的齐全。能充分保证在拍摄A6、B6、A5、A4、A3尺寸的身份证、单据、开户资料等时的良好曝光聚焦及图像质量'
                },
            ],

            coreList:[
                {
                    img:require('@/assets/mobile_img/core1.png'),
                },
                {
                    img:require('@/assets/mobile_img/core2.png'),
                },
                {
                    img:require('@/assets/mobile_img/core3.png'),
                },
                {
                    img:require('@/assets/mobile_img/core4.png'),
                },
                {
                    img:require('@/assets/mobile_img/core5.png'),
                },
                {
                    img:require('@/assets/mobile_img/core6.png'),
                },
                {
                    img:require('@/assets/mobile_img/core7.png'),
                },
                {
                    img:require('@/assets/mobile_img/core8.png'),
                },
            ],
          

            detailList:[
                {
                    text:'拍摄范围',
                    subText:'A3'
                },
                {
                    text:'主摄像头',
                    subText:'1000万像素、分辨率3648*2736'
                },
                {
                    text:'副摄像头',
                    subText:'200万像素、分辨率1600*1200'
                },
            ],
            detailListAll:[
                {
                    text:'拍摄范围',
                    subText:'A3'
                },
                {
                    text:'主摄像头',
                    subText:'1000万像素、分辨率3648*2736'
                },
                {
                    text:'副摄像头',
                    subText:'200万像素、分辨率1600*1200'
                },
                {
                    text:'拍摄速度',
                    subText:'1s'
                },
                {
                    text:'辅助光源',
                    subText:'自然光/5颗大功率LED补光灯，三级触控'
                },
                {
                    text:'扫描介质',
                    subText:'票据/单据/文件/资料/立体实物等'
                },
                {
                    text:'图片格式',
                    subText:'JPG、TIF、PDF、BMP、TGA、PCX、PNG、RAS'
                },
                {
                    text:'录像格式',
                    subText:'AVI、WMV'
                },
                {
                    text:'感光元件',
                    subText:'大面积1/2.5高清CMOS传感器：符合UVC、无驱'
                },
                {
                    text:'标配接口',
                    subText:'USB2.0'
                },
                {
                    text:'电源方式',
                    subText:'USB或DC5~12V外接电源供电'
                },
                {
                    text:'操作系统',
                    subText:'WindowsXP、Vista、Win7/8/10、Linux'
                },
            ],
            isShowDetail:true,
        }
    },
    methods:{
        unfoldFunc(){
            this.isUnfoldAll = !this.isUnfoldAll;
        },
        isShowFunc(){
            this.isShowDetail = !this.isShowDetail;
        }
    }
}
</script>

<style lang='scss' scoped>
.hardware_wrapper{
     width: 100%;
     background: #fff;
     .home_center{
         width: 100%;
         padding: 0 16px;
     }
    .home_img{
        width: 100%;
        height: 160px;
        background: url('../assets/mobile_img/hardwareBanner.png') no-repeat;
        background-size: 100% 100%;
        padding:33px 0 0 16px;
        .title{
            font-size: 24px;
            font-weight: bold;
            margin-bottom: 12px;
        }
        >span{
            font-size: 12px;
            color:#606266;
        }
    }
    .specifications_wrapper{
        margin-bottom: 8px;
        display: flex;
        justify-content: center;
        >img{
            width: 146px;
            height: 152px;
        }
    }
    .detail_img{
        margin-bottom: 15px;
        >img{
             width: 100%;
            height: 476px;
        }
        .detail_item{
            display: flex;
            justify-content: space-between;
            font-size: 12px;
            color:#666666;
            height: 36px;
            align-items: center;
            border-bottom: 1px solid #e7e7e7;
            margin-top: 4px;
            >span{
                font-size: 14px;
                color:#333;
                font-weight: bold;
            }
        }
        .unfold{
            width: 100%;
            height: 40px;
            font-size: 16px;
            line-height: 40px;
            text-align: center;
            color:#606266;
        }
    }
    .product_img{
         >img{
            width: 100%;
            height: 168px;
        }
        margin-bottom: 15px;
    }
    .characteristic_wrapper{
        width: 100%;
        background: #fff;
        border-radius: 2px;
        padding:0 8px;
        margin-bottom: 8px;
        box-shadow: 0 2px 10px 0 #e7e7e7cc;
        .unfold{
            width: 100%;
            height: 40px;
            font-size: 16px;
            line-height: 40px;
            text-align: center;
        }
        .item{
            border-bottom: 1px solid #e7e7e7;
            padding:8px 0;
            font-size: 12px;
            color:#666666;
            line-height: 16px;
            .item_title{
                display: flex;
                font-size: 14px;
                font-weight: bold;
                margin-bottom: 8px;
                font-size: #333;
                .label{
                    width: 14px;
                    height: 14px;
                    border-radius: 50%;
                    background: #0654c4;
                    margin-right: 4px;
                }
            }
            .text{
                padding:0 16px;
            }
        }
    }
    .carousel_wrapper{
        height: 190px;
        width: 100%;
        overflow: auto;
        margin-bottom: 15px;
        display: flex;
        flex-wrap: nowrap;
        >img{
            // width: 173px;
            // height: 100%;
            margin-right:0px;
        }
    }
    .industry_wrapper{
        display: flex;
        // padding:0 30px;
        margin-bottom: 15px;
        .industry_item{
            display: flex;
            flex-direction: column;
            align-items: center;
            >img{
                width: 60px;
                height: 60px;
            }
            text-align: center;
            flex:1;
            margin-right: 26px;
            &:last-child{
                margin-right: 0px;
            }
            font-size: 14px;
            line-height: 22px;
            font-weight: bold;
        }
    }

     .problem_img{
        >img{
            width: 100%;
            height: 143px;
        }
    }
    .machine_wrapper{
        width: 100%;
        display: flex;
        justify-content: center;
        >img{
            width: 230px;
            height: 214px;
        }
    }
    .down_type{
        display: flex;
        margin-bottom: 15px;
        .type_item{
            flex:1;
            height: 80px;
            border-radius: 8px;
            background: #ffffff;
            box-shadow: 0 4px 10px 0 #e7e7e74d;
            margin-right: 7px;
            padding-top:8px;
            display: flex;
            flex-direction: column;
            align-items: center;
            font-size: 14px;
            font-weight: bold;
            color:#0654C4;
            line-height: 22px;
            text-align: center;
            box-shadow: 0 4px 10px 0 #e7e7e74d;
            &:last-child{
                margin-right: 0px;
            }
            >img{
                width: 16px;
                height: 16px;
            }
        }
    }
}
</style>