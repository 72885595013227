<template>
   <div class="home_wrapper">
       <div class="home_img">
            <div class="title">汉图软件</div>
            <span>行业解决方案提供者</span>
            
       </div>

       <commonTitle title="公司简介" sub-title="COMPANY PROFILE"/>

        <div class="crop_intro">
           <!-- <img :src="comPro" alt=""> -->
           <div class="crop_message">
             <div class="paragraph">
                 山东汉图软件有限公司（以下简称汉图软件）成立于2013年，公司位于济南市高新技术开发区齐鲁软件园。汉图软件是专注于行业应用软件产品研发、推广、销售以及运营服务的高新技术企业。定位于系统解决方案的提供者，利用供给侧和需求侧关系互动来建立无限增值的可能性。
             </div> 
             <div class="paragraph">
                公司拥有稳定规模的技术研发团队，在文档影像化处理、OCR技术、数据挖掘技术、移动互联应用开发等领域拥有自主知识产权的核心技术，已研发了数字化档案管理，移动应用服务，数据集成及数据挖掘服务、基金监管服务等产品。
 </div> 
             <div class="paragraph">
                  主要面向政府以及企业提供系统建设规划咨询、系统搭建、数据资源建设及运营服务。
            </div> 
             <div class="paragraph">
                 公司以“责任”、“品质”、“创新”、“效率”为发展理念，把持续提供高品质的产品服务视为公司责任和生存根本，将与客户共赢作为公司持续发展的动力。“快乐工作、认真生活”是企业的文化信条，公司构建员工持续进步的人文环境和工作氛围，让员工在工作实践中不断提高，伴随公司一起成长。
             </div> 
            
            </div>
        </div>



       <commonTitle title="数据中台" sub-title="DATA PRODUCT"/>

       <div class="describe">整合、治理和扩展跨领域、跨平台的数据资源，将数据封装成服务，进而为企业创造价值。</div>

       
      <rotation/>
       

       <commonTitle title="政务产品" sub-title="GOVERNMENT PRODUCT"/>

       <div class="describe">为政府的全面信息化建设提供一体化政务解决方案打通多部门协作，提升政府办公效率。</div>

       <produceSwiper/>
   </div>
</template>

<script>
import commonTitle from "./components/commonTitle.vue";

import rotation from './components/rotation.vue'
import produceSwiper from './components/produceSwiper.vue'
export default {
    components:{
        commonTitle,
        rotation,
        produceSwiper
    },
    mounted(){
         
    },
    data(){
        return{
            homeImg:require('@/assets/mobile_img/homeBanner.png'),
            comPro:require('@/assets/img/com-pro.png'),

            synopsisList:[
                {
                    img:require('@/assets/img/synopsis1.png'),
                    text:'双软件认证企业'
                },
                {
                    img:require('@/assets/img/synopsis2.png'),
                    text:'10+优秀解决方案奖项'
                },
                {
                    img:require('@/assets/img/synopsis3.png'),
                    text:'40+专利著作者'
                },
                {
                    img:require('@/assets/img/synopsis4.png'),
                    text:'50+细分行业定制'
                },
            ],

            projectList:[
               {
                   img:require('@/assets/img/data1.png'),
                   title:'基础数据建设',
                   detail:'充分利用现有数据资源，整理、治理和扩展跨领域、跨平台数据，数据共用共享，将数据封装成服务，进而为企业创造价值。'
               },
               {
                   img:require('@/assets/img/data2.png'),
                   title:'用户画像+PUSH',
                   detail:'通过用户属性、行为、偏好等图标圈选目标用户群，分析用户特征、发掘用户需求特点，在各个业务场景下进行活动效果评价   。'
               },
               {
                   img:require('@/assets/img/data3.png'),
                   title:'数据大屏可视化',
                   detail:'对相关数据进行系统、深入的分析、研究、形成可视化检测、预警、预测等主题分析报告，为企业发展提供参考和辅助决策。'
               },
            ],

            
        }
    }
}
</script>

<style lang='scss' scoped>
.home_wrapper{
    width: 100%;
     .home_img{
        width: 100%;
        height: 160px;
        background: url('../assets/mobile_img/homeBanner.png') no-repeat;
        background-size: 100% 100%;
        padding:33px 0 0 16px;
        .title{
            font-size: 24px;
            font-weight: bold;
            margin-bottom: 12px;
        }
        >span{
            font-size: 12px;
            color:#606266;
        }
    }
    

  
  


    .crop_intro{
    //    height: 64px;
       width: 100%;
       display: flex;
       margin-bottom: 12px;
       padding:0 15px;
       >img{
           width: 120px;
           height: 100%;
           margin-right: 8px;
       }
       .crop_message{
           flex:1;
           font-size: 12px;
           line-height: 16px;
           .paragraph{
               text-indent: 2em;  
           }
       }
   }

   .title{
       width: 100%;
       font-size: 16px;
       font-weight: bold;
       margin-bottom: 4px;
   }
   .sub_title{
       color:#666666;
       font-size: 12px;
       line-height: 16px;
       margin-bottom: 8px;
   }
    .item_wrapper{
       width: 100%;
       height: 118px;
       display: flex;
       .item{
           flex:1;
           height: 100%;
           border-radius: 2px;
           background: #fff;
           margin-right: 4px;
           padding:8px 12px 0 12px;
           color:#3F74ED;
           font-size: 12px;
           text-align: center;
           
           &:last-child{
                margin-right: 0px;
           }

           >img{
               width: 60px;
               height: 58px;
           }
       }
    }
    .describe{
        color:#9E9E9E;
        font-size: 12px;
        text-align: center;
        margin-bottom: 10px;
        padding: 0 16px;
    }


    .data_project{
        display: flex;
        margin-bottom: 15px;
        .data_item{
            flex:1;
            margin-right: 10px;
            border-radius: 2px;
            background: #fff;
            padding: 7px;
            display: flex;
            flex-direction: column;
            align-items: center;
            .project_name{
                font-size: 12px;
                font-weight: bold;
                margin-bottom: 10px;
                .bule{
                    height: 10px;
                    background:  linear-gradient(0deg, #3f74ed 0%, #3f74ed00 100%);
                    border-radius: 2px;
                    margin-top: -10px;
                }
            }
            .project_detail{
                font-size: 12px;
                color:#666666;
                line-height: 18px;
                text-align: center;
            }
            >img{
                width: 80px;
                height: 80px;
                margin-bottom: 8px;
            }
            &:last-child{
                margin-right: 0;
            }
        }
    }

    .government_wrapper{
        display: flex;
        margin-bottom: 15px;
        .government{
            flex:1;
            background: #fff;
            border: 1px solid #3f74ed;
            border-radius: 2px;
            padding:8px 10px;
            margin-right: 7px;
            font-size: 12px;
            color:#666666;
            line-height: 16px;
            .title{
                font-size: 14px;
                color:#333;
                font-weight: bold;
                margin-bottom: 8px;
                text-align: center;
            }
            &:last-child{
                margin-right: 0px;
            }
        }
    }
}
</style>