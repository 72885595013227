<template>
  <div class="completeContainer">
    <div class="banner">
      <div class="banner-text">
        <p class="text-fz-88">汉图智能硬件</p>
        <p class="text-fz-48">汉图高拍仪HT-G1000A3</p>
        <!-- <p class="text-fz-22">1000万像素超清扫描，A3大幅面，一秒扫描</p> -->
      </div>
    </div>
    <div class="product_presentation">
      <div class="trait">
        <common-title title="产品优势" sub-title="PRODUCTADVANTAGES"></common-title>
        <div class="trait_body">
          <img src="@/assets/img/product-advantages.png" alt />
        </div>
      </div>
      <!-- 产品特点 -->
      <div class="productFeatures">
        <common-title title="产品特点" sub-title="CHARACTERISTIC"></common-title>

        <div class="productFeatures_body">
          <div class="left">
            <div class="item">
              <div class="title">
                <div class="square"></div>
                <span>双摄像头同步拍摄，最大支持A3幅面</span>
              </div>
              <div class="content">
                主摄像头1000万高清像素，分辨率可调；副摄像头200万像素，采用广角镜头，可水
                平270° 和上下45°旋转，灵活调整拍摄角度，进行无死角拍摄；支持最大幅面A3，可
                拍A4、A5、A6、A7、证件类
              </div>
            </div>
            <div class="item">
              <div class="title">
                <div class="square"></div>
                <span>机身文稿台一体，拍摄定位更精准</span>
              </div>
              <div
                class="content"
              >配置高精度流线型硬质文稿台，最大化的缩小了产品面积，所占的台面空间更小，主体支架可微调位置以实现文稿的精准定位，方便快速放置文稿并保证视频和图像的齐全。能充分保证在拍摄A6、B6、A5、A4、A3尺寸的身份证、单据、开户资料等时的良好曝光聚焦及图像质量</div>
            </div>
            <div class="item">
              <div class="title">
                <div class="square"></div>
                <span>提供强大的后期开发和系统集成技术支持</span>
              </div>
              <div class="content">
                可提供各种开发环境下的SDK包，包括各种图像获取、参数设置及处理算法（如框选、
                裁边、纠偏等），供产品无缝集成
              </div>
            </div>
          </div>
          <div class="right">
            <div class="item">
              <div class="title">
                <div class="square"></div>
                <span>智能触控LED补光灯，只需轻触并可自如开关LED灯</span>
              </div>
              <div class="content">
                配备高亮度LED补光灯，采用智能触控开关控制，只需轻触即可自由开关补光灯，在
                光线较暗时开启补光灯亦能保障正常的拍摄工作
              </div>
            </div>
            <div class="item">
              <div class="title">
                <div class="square"></div>
                <span>支持YUV，MJPG格式输出</span>
              </div>
              <div class="content">
                创新摄像头输出设计，支持YUV、MJPG格式输出，拍摄出来的图片更小，确又不会影
                响画质
              </div>
            </div>
            <div class="item">
              <div class="title">
                <div class="square"></div>
                <span>OCR文字识别</span>
              </div>
              <div class="content">
                汉图高拍仪搭载了OCR文件识别技术，可自动分析版面，能识别多种文字、
                表格、条码、C++和Java形式语言及特殊符号等，识别率高达98%，支持多语言、符号
                和数字的编排识别，识别结果保持与原图像相同的排版，并可一键导出为word或excel
                文件
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 产品规格 -->
      <div class="specification">
        <common-title title="产品规格" sub-title="SPECIFICATIONS"></common-title>
        <div class="specification_body">
          <div class="left">
            <img src="@/assets/img/high.png" alt />
          </div>
          <div class="right">
            <img src="@/assets/img/high-content.png" alt />
          </div>
        </div>
      </div>
    </div>
    <div class="core_function">
      <common-title title="八大软件核心功能" sub-title="COREFUNCTIONS"></common-title>

      <div class="coreFunction_body">
        <div class="oneLine">
          <div class="modules">
            <img src="@/assets/img/kenel1.png" alt />
            <span>友好界面，轻松体验</span>
            <p>良好的结构布局，让用户体验更轻松；更有目录管理功能，让工作有条不紊；卸载后再安装即可恢复原有工作目录。</p>
          </div>
          <div class="modules">
            <img src="@/assets/img/kenel2.png" alt />
            <span>全能高速，1秒完成</span>
            <p>多镜头自由切换、文件、资料、票据、证件、数据、实物均可拍摄，鼠标点击扫描按钮，一键完成扫描，最快只需1秒！还支持自动检测拍照和条码识别并命名。</p>
          </div>
          <div class="modules">
            <img src="@/assets/img/kenel3.png" alt />
            <span>图像编辑处理</span>
            <p>软件拥有强大的图像编辑处理功能，可对图片进行剪切、旋转、添加文字、JPG质量调节、自动纠偏裁边、添加水印等处理。更有全屏预览、缩略图展示，贴心实用。</p>
          </div>
          <div class="modules">
            <img src="@/assets/img/kenel4.png" alt />
            <span>领先的文字识别技术</span>
            <p>汉图高拍仪拥有文字识别技术，支持word等多种导出格式。</p>
          </div>
        </div>
        <div class="oneLine">
          <div class="modules">
            <img src="@/assets/img/kenel5.png" alt />
            <span>定时拍摄，快速翻拍</span>
            <p>设置拍摄时间间隔，软件自动定时拍摄，快速翻拍书籍和大量的文件。</p>
          </div>
          <div class="modules">
            <img src="@/assets/img/kenel6.png" alt />
            <span>录制视频</span>
            <p>可录制并回放多媒体演示视频，适用于实验操作步骤、产品培训和演示等。</p>
          </div>
          <div class="modules">
            <img src="@/assets/img/kenel7.png" alt />
            <span>PDF转换</span>
            <p>一键将扫描的图片文件转换为PDF文件，支持批量上传，可在多种设备上查看。</p>
          </div>
          <div class="modules">
            <img src="@/assets/img/kenel8.png" alt />
            <span>打印、传真、电子邮件</span>
            <p>连接打印机打印扫描文件，支持批量打印及打印预览；发送网络传真和电子邮件，实现办公一体化。</p>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="help">
      <common-title title="助力多行业成功" sub-title="MUTIINDUSTRY"></common-title>

      <div class="line">
        <div class="modules">
          <img src="@/assets/img/office.png" alt />
          <div>办公应用</div>
        </div>
        <div class="modules">
          <img src="@/assets/img/zseyo0qpul.png" alt />
          <div>政府应用</div>
        </div>
        <div class="modules">
          <img src="@/assets/img/bank.png" alt />
          <div>银行应用</div>
        </div>
        <div class="modules">
          <img src="@/assets/img/message.png" alt />
          <div>通讯应用</div>
        </div>
      </div>
    </div> -->


    <div class="download-box">
      <common-title title="软件下载" sub-title="DOWNLOAD"></common-title>

      <div class="download_bottom">
        <div class="left">
          <img src="@/assets/img/download-banner.png" alt />
        </div>
        <div class="right">
          <div class="download v6" @click="downLoad1">
            <span>汉图高拍仪V6.0.5驱动下载</span>
            <img src="@/assets/img/download.png" alt />
          </div>
          <div class="download v2" @click="downLoad2">
            <span>汉图高拍仪网页控件安装程序2.2下载</span>
            <img src="@/assets/img/download.png" alt />
          </div>
        </div>
      </div>
    </div>
    <div class="question">
      <common-title title="常见问题" sub-title="PROBLEM"></common-title>
      <div class="pro-img">
        <img src="@/assets/img/problem.png" alt />
      </div>
    </div>
  </div>
</template>

<script>
import commonTitle from "./components/commonTitle.vue";
export default {
  name: "Hardware",
  components: {
    commonTitle
  },

  props: {},

  data() {
    return {};
  },
  methods:{
     downLoad1() {
      window.open(
        'https://pan.baidu.com/s/1ojeyzaP2P0ha00CFYUQMJg?pwd=4mtm'
      );
    },
    downLoad2() {
      window.open(
        'https://pan.baidu.com/s/1SoR3lFiQvWR6LCNubC918w?pwd=a27c'
      );
    }
  }
};
</script>
<style lang="scss" scoped>
.completeContainer {
  width: 100%;
  height: 100%;

  background: #f9fafe;

   .download-box {
    padding-top: 60px;
    margin-bottom: 50px;
    .download_bottom {
      display: flex;
      justify-content: center;
      margin-top: 20px;
      .left {
        width: 779px;
        height: 516px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .right {
        width: 376px;
        .download {
          height: 116px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          background: #ffffff;
          box-shadow: 0px 4px 10px 0px rgba(231, 231, 231, 0.3);
          border-radius: 8px 8px 8px 8px;
          font-size: 18px;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #0654c4;
          line-height: 25px;
          cursor: pointer;
          img {
            width: 24px;
            height: 24px;
            margin: 10px;
          }
        }
        .v6 {
          margin-top: 65px;
        }
        .v2 {
          margin-top: 155px;
        }
      }
    }
    .dot {
      width: 53px;
      margin: 64px auto 0;
      img {
        width: 53px;
        height: 13px;
      }
    }
  }

  .question {
    width: 894px;
    margin: 0 auto;
    padding-bottom: 50px;
    .pro-img {
      height: 266px;
      margin-top: 20px;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
  .banner {
    width: 100%;
    height: 500px;
    background: url("../assets/img/software-banner.png") no-repeat center;
    background-size: 100% 100%;
    display: flex;
    align-items: center;
    .banner-text {
      margin-left: 360px;
      .text-fz-88 {
        font-size: 88px;
        font-family: PingFang SC-Semibold, PingFang SC;
        font-weight: 600;
        color: #0654c4;
        line-height: 123px;
      }
      .text-fz-48 {
        font-size: 48px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #0654c4;
        line-height: 67px;
      }
      .text-fz-22 {
        margin-top: 10px;
        font-size: 22px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #0654c4;
        line-height: 31px;
      }
    }
  }
  .product_presentation {
    width: 100%;
    .trait {
      width: 100%;
      margin-top: 50px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .trait_body {
        width: 1200px;
        height: 371px;
        margin-top: 16px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .productFeatures {
      width: 100%;
      margin-top: 50px;
      .productFeatures_body {
        display: flex;
        justify-content: center;
        margin-top: 8px;
        .item {
          min-width: 588px;
          min-height: 144px;
          padding: 15px;
          background: #ffffff;
          box-shadow: 0px 4px 10px 0px rgba(231, 231, 231, 0.3);
          border-radius: 8px 8px 8px 8px;
          .title {
            display: flex;
            align-items: center;
            font-size: 18px;
            font-family: PingFang SC-Semibold, PingFang SC;
            font-weight: 600;
            color: #333333;
          }
          .square {
            min-width: 14px;
            min-height: 14px;
            margin-right: 8px;
            background: #0654c4;
            border-radius: 7px;
          }
          .content {
            padding: 0 22px;
            margin-top: 5px;
            font-size: 14px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
            line-height: 20px;
          }
        }
        .left {
          width: 588px;
          display: flex;
          flex-direction: column;
          margin-right: 24px;
          .item {
            margin-top: 8px;
            flex: 1;
          }
        }
        .right {
          width: 588px;
          display: flex;
          flex-direction: column;
          .item {
            margin-top: 8px;
            flex: 1;
          }
        }
      }
    }
    .specification {
      width: 100%;
      margin-top: 50px;
      padding: 50px 0;
      background-color: #fff;
      .specification_body {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 20px;
        .left {
          width: 573px;
          height: 596px;
          margin-right: 57px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .right {
          width: 600px;
          height: 696px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
  .core_function {
    width: 100%;
    margin-top: 50px;
    .coreFunction_body {
      margin-top: 16px;

      .oneLine {
        width: 100%;
        display: flex;
        justify-content: center;

        .modules {
          width: 288px;
          height: 275px;
          padding: 0 30px;
          margin-right: 16px;
          background: #ffffff;
          box-shadow: 0px 4px 10px 0px rgba(231, 231, 231, 0.3);
          border-radius: 8px 8px 8px 8px;
          display: flex;
          flex-direction: column;
          align-items: center;

          img {
            width: 74px;
            height: 74px;
            margin-top: 24px;
          }
          span {
            margin: 8px 0;
            font-size: 18px;
            font-family: PingFang SC-Semibold, PingFang SC;
            font-weight: 600;
            color: #333333;
            line-height: 25px;
          }
          p {
            font-size: 14px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
            line-height: 20px;
          }
        }
        :last-child {
          margin-right: 0;
        }
      }
      .oneLine:first-child {
        margin-bottom: 17px;
      }
    }
  }
  .help {
    margin-top: 60px;
    padding-bottom: 50px;
    .line {
      width: 100%;
      margin-top: 16px;
      display: flex;
      justify-content: center;
      .modules {
        width: 260px;
        // height: 100%;
        margin-right: 53px;
        img {
          width: 260px;
          height: 261px;
        }
        div {
          text-align: center;
          font-size: 18px;
          font-family: PingFang SC-Semibold, PingFang SC;
          font-weight: 600;
          color: #333333;
          line-height: 25px;
        }
      }
      .modules:last-child{
        margin-right: 0;
      }
    }
  }
}
</style>
