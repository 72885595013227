import Vue from 'vue'
import VueRouter from 'vue-router'

//pc端页面
import Home from '@/views/Home.vue'
import Product from '@/views/product'
import Hardware from '@/views/intelligentHardware.vue'
import Service from '@/views/service.vue'
import About from '@/views/About.vue'
import Errorpage404 from '@/views/errorpage404.vue'
import ProductLicense from '@/views/productLicense.vue'


//移动端  页面
import mobileHome from '@/mobile-pages/home.vue'
import mobileProduct from '@/mobile-pages/product'
import mobileHardware from '@/mobile-pages/intelligentHardware.vue'
import mobileService from '@/mobile-pages/service.vue'
import mobileAbout from '@/mobile-pages/About.vue'

function isMobile() {
  const flag = navigator.userAgent.match(
    /(phone|pad|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows phone)/i
  )
  return flag
}


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  // {
  //   path: '/product',
  //   name: 'product',
  //   component: Product
  // },
  {
    path: '/hardware',
    name: 'hardware',
    component: Hardware
  },
  // {
  //   path: '/service',
  //   name: 'service',
  //   component: Service
  // },
  // {
  //   path: '/about',
  //   name: 'about',
  //   component: About
  // },
  {
    path: '/404',
    name: '404',
    component: Errorpage404
  },
  {
    path: '*',
    redirect: '/404'
  },
  {
    path: '/productLicense',
    name: 'productLicense',
    component: ProductLicense,
    meta:{
      text:'productLicense'
   }
  },
]

//移动端路由  菜单
const mobileRoutes = [
  {
    path: '/',
    name: 'home',
    component: mobileHome,
    meta:{
       text:'首页'
    }
  },

  {
    path: '/hardware',
    name: 'hardware',
    component: mobileHardware,
    meta:{
      text:'智能硬件'
   }
  },
  {
    path: '/productLicense',
    name: 'productLicense',
    component: ProductLicense,
    meta:{
      text:'productLicense'
   }
  },

  // {
  //   path: '/about',
  //   name: 'about',
  //   component: mobileAbout,
  //   meta:{
  //     text:'关于我们'
  //  }
  // },
]




const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_SRC,
  routes: isMobile() ? mobileRoutes:routes
  // routes:routes
})

export default router
