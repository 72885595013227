<template>
    <div class="product_wrapper">
        <div class="home_img">
           <img :src="productBanner" alt="">
            <commonTitle title="数据中台" sub-title="DATA"/>
        </div>

        <div class="data_wrapper">
            <div class="data_item" v-for="(item,index) in dataList" :key="index">
                <img :src="item.img" alt="">
                <div class="title">{{item.title}}</div>
                {{item.detail}}
            </div>
        </div>

         <commonTitle title="智能运营" sub-title="OPERATE"/>

         <div class="home_img">
           <img :src="operateImg" alt="">
        </div>


        <commonTitle title="数据大屏" sub-title="VISUALIZATION"/>

        <div class="sub_title">
            数据大屏可视化<br/>更直观更智能的决策场景体验
        </div>
        
        <div class="tre_title">
             实时监测企业数据，洞察业务变化规律及未来趋势，数据驱动服务，辅助企业决策，助力企业增长。
        </div>

        <div class="screen_img">
           <img :src="echartImg" alt="">
        </div>

        <!---------分个------------->
        <commonTitle title="电子档案一体化" sub-title="ARCHIVES"/>

        <div class="sub_title">
            统一的综合管理平台<br/>亦支持模块化部署
        </div>
        
        <div class="tre_two_title">
             <span>业务档案管理系统</span>
             <span>人事档案管理系统</span>
        </div>
         <div class="tre_two_title">
             <span>档案实时监控系统</span>
             <span>2D可视化库房系统</span>
        </div>


        <div class="screen_img">
           <img :src="govermentImg" alt="">
        </div>

        <!---------分个------------->

        <commonTitle title="待遇资格大数据认证" sub-title="CERTIFICATION"/>

        <div class="sub_title">
            为群众 - - <br/>解难点·疏堵点·去痛点
        </div>
    
        <div class="tre_title">
             依托大数据平台代替传统意义上的待遇资格全员认证服务，做到认证服务零打扰，实现“寓认证于无形，寓认证于服务”的总体目标，让数据多跑路，让群众少跑腿。
        </div>

        <div class="screen_img">
           <img :src="qualificationImg" alt="">
        </div>


        <!---------分个------------->

        <commonTitle title="供需平台系统" sub-title="CERTIFICATION"/>

        <div class="sub_title">
            Web端·移动端·小程序
        </div>
    
        <div class="tre_title">
            基于人力资源市场数据，再通过精准匹配、AI智能推荐等技术，向用人单位和求职者提供个性化的职位推荐、就业培训、员工调剂等服务，实现多渠道、全流程的新型公共就业。
        </div>

        <div class="screen_img">
           <img :src="supplyImg" alt="">
        </div>


    </div>
</template>

<script>
import commonTitle from "./components/commonTitle.vue";
export default {
    components:{
        commonTitle
    },
    data(){
        return{
            productBanner:require('@/assets/mobile_img/productBanner.png'),
            operateImg:require('@/assets/img/intelligentOperation.png'),
            echartImg:require('@/assets/img/data_screen.png'),
            govermentImg:require('@/assets/img/goverment-data.png'),
            qualificationImg:require('@/assets/img/qualification.png'),
            supplyImg:require('@/assets/img/supply.png'),
            
            dataList:[
                {
                    img:require('@/assets/img/data-capture1.png'),
                    title:'数据采集',
                    detail:'支持多种数据采集方式，统一采集、存储和加工，制定标准统一的数据规范，对数据资源进行集中管理和共享。'
                },
                 {
                    img:require('@/assets/img/data-capture2.png'),
                    title:'数据共享',
                    detail:'开放性设计思想，打破信息孤岛，数据共享应用。易于随时随地的接入各类数据源，能够实现即开即用，节省开发资源。'
                },
                {
                    img:require('@/assets/img/data-capture3.png'),
                    title:'数据治理',
                    detail:'数据治理体系，涵盖元数据管理、数据质量管理、数据安全管理等重要环节，为大数据的深度利用，提供坚实的基础。'
                },
                 {
                    img:require('@/assets/img/data-capture4.png'),
                    title:'数据服务',
                    detail:'在数据资源基础上，对业务主题、风险预警、分析预测做特定分析，将数据封装成服务，进而为企业创造价值。'
                },
            ]
        }
    }
}
</script>

<style lang='scss' scoped>
.product_wrapper{
    width: 100%;
    .home_img{
      >img{
          width: 100%;
          height: 140px;
      }
    }

    .screen_img{
        >img{
          width: 100%;
          height: 200px;
        }
        margin-bottom: 15px;
    }

    .tre_two_title{
        display: flex;
        padding:0 30px;
        justify-content: space-between;
        font-size: 12px;
        line-height: 16px;
        margin-bottom: 8px;
    }

    .data_wrapper{
        width: 100%;
        display: flex;
        margin-bottom: 15px;
        .data_item{
            flex:1;
            background: #fff;
            padding:3px;
            display: flex;
            flex-direction: column;
            align-items: center;
            border: 1px solid #3f74ed;
            border-radius: 2px;
            margin-right: 1px;
            font-size: 12px;
            color:#606266;
            line-height: 16px;
            >img{
                width: 74px;
                height: 60px;
            }
            .title{
                font-size: 14px;
                color:#3F74ED;
                line-height: 22px;
                font-weight: bold;
            }
        }
    }
    .sub_title{
        color:#3F74ED;
        font-size: 14px;
        font-weight: bold;
        line-height: 22px;
        text-align: center;
        margin-bottom: 8px;
    }
    .tre_title{
        font-size: 12px;
        color:#666666;
        line-height: 16px;
        margin-bottom: 8px;
    }
}
</style>