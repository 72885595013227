import Vue from 'vue'
import App from './App.vue'
import router from './router'


import './registerServiceWorker'
import '@/utils/rem'


// 2. 引入 移动端组件样式
import Vant from 'vant';
import 'vant/lib/index.css';
Vue.use(Vant);


import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);



Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
