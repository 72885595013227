import request from "@/utils/request";

// 产品授权
export const getAuthCode = (param) =>
    request(
        {
            method: "hitoo.oa.license.authcode"
        },
        param
    );
