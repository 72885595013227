<template>
    <div class="mobile_wrapper">
        <div class='mobile_header_wrapper'>
            <img :src="loginImg" alt="">
            <div class="crop_name">
                {{routerName}}
            </div>
            <div class="ment_list">
                <img :src="xImg" alt="" v-if="isUnfold" @click="unfoldMenu">
                <img :src="menuImg" alt="" v-else @click="unfoldMenu">
            </div>  
        </div>


        <div class="menu_wrapper" v-if="isUnfold">
            <menuList @close='close'/>
        </div>
    </div>
</template>

<script>
import menuList from '../components/menu.vue'
export default {
    components:{
        menuList
    },
    computed:{
        routerName(){
            return this.$route.meta.text;
        }
    },
    data(){
       return{
           loginImg:require('@/assets/img/logo.png'),
           menuImg:require('@/assets/mobile_img/list.png'),
           xImg:require('@/assets/mobile_img/x.png'),
           isUnfold:false,
       }
   },
   methods:{
       unfoldMenu(){
           this.isUnfold = !this.isUnfold;
       },
       close(data){
           this.isUnfold = false;
       }
   }
}
</script>

<style lang='scss' scoped>
.mobile_wrapper{
   position: relative;
   width: 100%;
   .menu_wrapper{
      position: absolute;
      top:20px;
      width: 100%;
      box-shadow: 0 4px 6px 0 #e7e7e780;
      background: #fff;
      z-index: 99;
  }
}

.mobile_header_wrapper{
  width: 100%;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding:0 16px;
  font-size: 16px;
  font-weight: bold;
  background: #fff;
  >img{
      width: 67px;
      height: 26px;
  }
  .crop_name{
     
   }
 

  .ment_list{
      width: 67px;
      display: flex;
      justify-content: flex-end;
      >img{
          width: 24px;
          height: 24px;
          border-radius: 2px;
          overflow: hidden;
      }
  }
}
</style>