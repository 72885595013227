<template>
  <div class="container-box">
    <header>
      <div class="box">
        <img class="logo" src="@/assets/img/logo.png" alt />
        <div class="right">
          <nav>
            <ul>
              <li v-for="(item,index) in linkItem" :key="index">
                <router-link :to="item.src" exact-active-class="eac">
                  {{item.content}}
                  <!-- <i class="iconfont icon-youshang"></i> -->
                </router-link>
              </li>
            </ul>
          </nav>
          <div class="phone"> 
            <img class="phone-call" src="@/assets/img/phone-call.png" alt />
            <div>+400-881-0721</div>
          </div>
        </div>
      </div>
    </header>
  </div>
</template>

<script>
export default {
  data() {
    return {
      linkItem: [
        { src: "/", content: "首页" },
        // { src: "/product", content: "产品矩阵" },
        { src: "/hardware", content: "智能硬件" },
        // { src: "/service", content: "服务与支持" },
        // { src: "/about", content: "关于我们" }
      ]
    };
  }
};
</script>

<style lang="scss">
.container-box {
  height: 64px;
}
header {
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 999;
  background-color: #fff;
  .box {
    width: 1200px;
    // height: 64px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .logo {
      width: 123px;
      height: 48px;
    }
    .right {
      display: flex;
      align-items: center;
      a {
        text-decoration: none;
        color: #333;
        font-size: 18px;
        // font-weight: bold;
        &:valid {
          color: #333;
        }
      }
    }
    nav {
      li {
        line-height: 64px;
        // padding: 0 5px;
        width: 171.9696px;
        text-align: center;
        list-style: none;
        display: inline-block;
        color: #333333;
        white-space: nowrap;
        overflow: hidden;
        a {
          padding: 0 30px;
        }
        i {
          width: 7px;
          height: 7px;
          // margin-left: 13px;
          margin-bottom: 4px;
        }
      }
      .eac {
        color: #2875f5;
      }
    }
    .phone {
      width: 145px;
      font-size: 12px;
      white-space: nowrap;
      overflow: hidden;
      display: flex;
      img{
        width: 17px;
        height: 17px;
        margin-right: 15px;
      }
    }
  }
}
</style>